import AppFrame from "../../Common/AppFrame";
import InnerPage from "../../AboutUs/InnerPage";

function BecomeSponsor() {
  return (
    <AppFrame>
      <InnerPage title={"Become a Sponsor"}> 
      <div className="">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      </div>
      </InnerPage>
    </AppFrame>
  );
}

export default BecomeSponsor;
