import { useTranslation } from 'react-i18next';
import '../css/PaymentDetails.css'
function PaymentDetails({EventName, EventDate, EventLocation, MemberPrice, nonMemberPrice, MemberQuantity, nonMemberQuantity}) {
    const {t} = useTranslation()
    return(
        <div className='PaymentDetailsContainer'>
            <div className='EventInfo'>
                <div id='name'>{EventName}</div>
                <div id='date'>{EventDate}</div>
                <div id='location'>{EventLocation}</div>
            </div>
            <div className='OrderSummary'>
                <div className='priceByLine'>
                    <h5 >{MemberQuantity} x Member tickets</h5>
                    <h5>CA${ MemberQuantity * MemberPrice }</h5>
                </div>
                <div className='priceByLine'>
                    <h5 >{nonMemberQuantity} x Non Member tickets</h5>
                    <h5>CA${ nonMemberQuantity * nonMemberPrice}</h5>
                </div>
                <hr/>
                <div className='priceByLine'>
                    <h5>{t('subtotal')}</h5>
                    <h5>CA${MemberQuantity * MemberPrice + nonMemberQuantity * nonMemberPrice}</h5>
                </div>
            </div>
        </div>
    );
}

export default PaymentDetails;