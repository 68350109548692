import {deleteFirebaseDocById, getAllCollectionDocs, setFirebaseDoc} from "../firebase-helper";
import { v4 as uuidv4 } from 'uuid';

const collection = "subscriptions"

export function getAllSubscriptions(onLoaded) {
    getAllCollectionDocs(collection, data => {
        onLoaded(data)
    })
}

export function addSubscription(data) {
    const id = uuidv4();
    setFirebaseDoc(collection, id, {
        id: id,
        name: data.name,
        price: data.price,
        description: data.description,
        benefits: data.benefits,
        priceUrl: data.priceUrl
    })
}

export function deleteSubscription(id) {
    deleteFirebaseDocById(id, collection)
}