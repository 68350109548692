import './css/Dropdown.css';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import SubDropdown from './SubDropdown';

export default function Dropdown({width, items}) {
    const navigate = useNavigate();
    const [subMenuItems, setSubMenuItems] = useState([]);
    const [subMenuTop, setSubMenuTop] = useState(0);
    const renderedItems = items ? items.map((item, index) =>
        <div
            key={item.label}
            className="DropdownItem"
            style={{
                width: width,
                borderBottomRightRadius: index === items.length -1 ? 5 : 0,
                borderBottomLeftRadius: index === items.length -1 ? 5 : 0
            }}
            onClick={() => {
                if (item.linkTo) {
                    navigate(item.linkTo)
                }
                if (item.onClick) {
                    item.onClick()
                }
            }}
            onMouseOver={() => {
                if (item.subMenuItems) {
                    setSubMenuItems(item.subMenuItems);
                    setSubMenuTop(index * 40)
                } else {
                    setSubMenuItems([]);
                }
            }}
        >
            <div className='DropdownLabel'>
                {item.label}
            </div>
            {item.subMenuItems && <div 
                className='arrow right'
                style={
                    {borderWidth: "0 2px 2px 0"}
                }
            />}
            
        </div>
    ) : <></>;
    return (
        <div className="DropdownContainer" style={
            {left: width/-3}
        }>
            {renderedItems}
            {subMenuItems.length > 0 && <SubDropdown width={width} items={subMenuItems} top={subMenuTop} />}
        </div>
    )
}