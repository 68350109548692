import './App.css';
import { Routes, Route, HashRouter } from "react-router-dom";
import Event from './Events/EventPage/Event';
import MemberList from './Members/MemberList'
import EventList from './Events/EventList';
import Home from './Home/Home';
import AboutUs from './AboutUs/AboutUs';
import Member from './Members/MemberPage/Member';
import Charter from './AboutUs/Charter';
import BecomeAMember from './Members/BecomeMember/BecomeAMember';
import CalendarPage from './Events/CalendarPage';
import EventRegistration from './Events/EventPage/EventRegistration';
import NewsPage from './News/NewsPage';
import NewsDetails from './News/NewsDetails';
import BecomeSponsor from './Members/BecomeSponsor/BecomeSponsor';
import AdminPage from './Firebase/Admin/AdminPage';

function App() {
  return (
    <HashRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="event/:eventId" element={<Event />} />
        <Route path="member/:memberId" element={<Member />} />
        <Route path="members/:filter" element={<MemberList />} />
        <Route path="events/:filter" element={<EventList />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="charter" element={<Charter />} />
        <Route path="become-a-member" element={<BecomeAMember />} />
        <Route path="become-a-sponsor" element={<BecomeSponsor />} />
        <Route path="calendar" element={<CalendarPage />} />
        <Route path="registration/:eventId" element={<EventRegistration />} />
        <Route path="news" element={<NewsPage />} />
        <Route path="news-details" element={<NewsDetails />} />
        <Route path="admin" element={<AdminPage />} />
      </Routes>
    </HashRouter>
  );
}

export default App;

export const mobileScreen = "(max-width: 1000px)";