import {deleteFirebaseDocById, getAllCollectionDocs, getFirebaseDoc, setFirebaseDoc} from "../firebase-helper";
import { v4 as uuidv4 } from 'uuid';

const collection = "events"


export function getAllEvents(onLoaded) {
    getAllCollectionDocs(collection, data => {
        onLoaded(data)
    })
}

export function addEvent(event) {
    const id = uuidv4();
    setFirebaseDoc(collection, id, {
        id: id,
        name: event.name,
        language: event.language,
        location: event.location,
        date: event.date,
        duration: event.duration,
        deadline: event.deadline,
        speakers: event.speakers,
        description: event.description,
        url: event.url,
        price: event.price,
        priceUrl: event.priceUrl,
        memberPrice: event.memberPrice,
        memberPriceUrl: event.memberPriceUrl
    })
}

export function deleteEvent(id) {
    deleteFirebaseDocById(id, collection)
}

export function getEventById(id, onLoaded) {
    getFirebaseDoc(collection, id, onLoaded)
}