import { useTranslation } from 'react-i18next';
import '../css/EventPrice.css'
import Input from '../../Common/Input';
import { useState } from 'react';
import Button from '../../Common/Button';
import { getMemberById } from '../../Firebase/Models/MembersModel';
import CIcon from '@coreui/icons-react';
import {cilCheck } from '@coreui/icons';

function EventPrice ({eventName,memberPrice,nonMemberPrice,MemberQuantity,nonMemberQuantity,decrementMember,incrementMember,decrementNonMember,incrementNonMember}) {
    const {t} = useTranslation();
    const [memberCode, setMemberCode] = useState();
    const [isMember, setIsMember] = useState(false);
    return(
        <div className="EventPriceContainer">
            <div style={{textAlign: 'left'}}>
                {t("Please enter member code for member rate")}
                <div className='EventMemberCodeInput'>
                    <Input value={memberCode} onChange={setMemberCode} disabled={isMember}/>
                    {!isMember && <Button label={t("validate")} onClick={() => {
                        getMemberById(memberCode, (member) => {
                            if (member) {
                                setIsMember(member.id)
                            }
                        }, () => {
                            setIsMember(false)
                        })
                    }}/>}
                    {isMember && <div className='CheckMark'> 
                        <CIcon icon={cilCheck} size="sm" className=''/>
                    </div>}
                </div>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th>{t("ticket_type")}</th>
                        <th>{t("rate")}</th>
                        <th>{t("quantity")}</th>
                    </tr>
                    {isMember && <tr>
                        <td>{t("member_rate")}</td>
                        <td>{memberPrice}</td>
                        <td className='QuantityContainer'>
                            <div className='minusButton'
                                    onClick={() => {
                                        decrementMember()
                                    }}
                                >
                                    -
                                </div> 
                                {MemberQuantity}
                                <div className='plusButton'
                                    onClick={() => {
                                        incrementMember()
                                    }}
                                >
                                    +
                                </div> 
                        </td>
                    </tr>}
                    <tr>
                        <td>{t("normal_rate")}</td>
                        <td>{nonMemberPrice}</td>
                        <td className='QuantityContainer'>
                            <div className='minusButton'
                                onClick={() => {
                                    if (nonMemberQuantity > 0){
                                        decrementNonMember();
                                    }
                                }}
                            >
                                -
                            </div> 
                            {nonMemberQuantity}
                            <div className='plusButton'
                                onClick={() => {
                                    incrementNonMember();
                                }}
                            >
                                +
                            </div> 
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default EventPrice;