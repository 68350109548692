import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBMsyB0C26x4VXP01Smb6uVrX6tlAfn-L8",
  authDomain: "ccec-26a7a.firebaseapp.com",
  projectId: "ccec-26a7a",
  storageBucket: "ccec-26a7a.appspot.com",
  messagingSenderId: "403352268589",
  appId: "1:403352268589:web:acf56f82c118ed0b23ada4",
  measurementId: "G-G5G97SKWBW"
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
