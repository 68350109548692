import './css/MemberThumbnail.css';
import { cilLibraryAdd } from '@coreui/icons';
import { useState } from 'react';
import NewMemberPopup from './NewMemberPopup';
import LabeledIcon from '../Common/LabeledIcon';

function NewMemberThumbnail() {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
    <div className="NewMemberThumbnailContainer"
        onClick={() => {setShowPopup(true)}}
    >
      <LabeledIcon label={"Add Member"} iconName={cilLibraryAdd} />
    </div>
        {showPopup && <NewMemberPopup 
                        close={() => {
                            setShowPopup(false)
                        }}
                    />
        }
    </>
  );
}

export default NewMemberThumbnail;
