import './css/Events.css'
import '../Common/css/text.css'
import '../Common/css/Arrow.css'
import '../Common/css/Calendar.css'
import EventThumbnail from './EventThumbnail';
import NewEventThumbnail from './NewEventThumbnail';
import AppFrame from '../Common/AppFrame';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AdminComponent from '../Firebase/Admin/AdminComponent';
import { useEffect } from 'react';
import { getAllEvents } from '../Firebase/Models/EventsModel';
import { useState } from 'react';
import { Timestamp } from '@firebase/firestore';
import { printFirebaseDate } from '../Tools/DateTool';
import Banner from './EventPage/Banner';
import { getLanguage } from '../Localization/LanguageHelper';

function EventList() {
    const {filter} = useParams();
    const {t, i18n} = useTranslation();
    const [events, setEvents] = useState([])
    useEffect(() => {
        getAllEvents(events => {
            setEvents(events)
        })
    }, [])

    const renderEventThumnail = (event) =>
        <EventThumbnail 
            key={event.id}
            imgUrl={event.url}
            name={getLanguage(event.name, i18n)}
            description={getLanguage(event.description, i18n)}
            eventColor={'#e1b7ed'}
            date={printFirebaseDate(event.date)}
            location={getLanguage(event.location, i18n)}
            id={event.id}
        />
    

    const renderedUpComingEvents = events.filter(e => e.date > Timestamp.now()).map(renderEventThumnail)
    const renderedPastEvents = events.filter(e => e.date <= Timestamp.now()).map(renderEventThumnail)
  return (
    <AppFrame>
    <div className="EventsSectionContainer">
        {(filter === 'upcoming' || filter === 'all') && <Banner title={t('upcoming_events')} />}
        {(filter === 'upcoming' || filter === 'all') && <div className='EventThumbnailsContainer'>
            {renderedUpComingEvents}
            <AdminComponent>
                <NewEventThumbnail />
            </AdminComponent>
        </div>}
        {filter === 'all' && <div style={{height: "50px"}}/>}
        {(filter === 'past' || filter === 'all') && <Banner title={t('past_events')} />}
        {(filter === 'past' || filter === 'all') && <div className='EventThumbnailsContainer'>
            {renderedPastEvents}
        </div>}
    </div>
    </AppFrame>
  );
}

export default EventList;
