import CIcon from '@coreui/icons-react';
import './css/MemberThumbnail.css';
import { useNavigate } from "react-router-dom";
import { cilTrash } from '@coreui/icons';
import AdminComponent from '../Firebase/Admin/AdminComponent';
import { deleteMember } from '../Firebase/Models/MembersModel';

function MemberThumbnail({imgUrl, name, description, id}) {
  const navigate = useNavigate();

  return (
    <div className="MemberThumbnailContainer"
      onClick={() => 
        {navigate(`/member/${id}`);}
      }
    >
      <AdminComponent>
                <div className='DeleteButton'
                    onClick={(e) => {
                        e.stopPropagation();
                        deleteMember(id)
                    }}
                >
                    <CIcon icon={cilTrash} size="custom" className=''/>
                </div>
      </AdminComponent>
      <div className="MemberImageContainer"
        style={{
            backgroundImage: "url(" + process.env.PUBLIC_URL + imgUrl + ")",
        }}
      ></div>
      <div className='MemberName'>{name}</div>
      <div className='MemberDetails'>
        {description}
      </div>
    </div>
  );
}

export default MemberThumbnail;
