import { loadStripe } from '@stripe/stripe-js';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51LwehnKGkQ2imUptMOL2TSjd2n5uc4I1LviPVqsJAmLpORTTWIaJcz37YclHr0kr3gmFA0eLPGb4lKvfrj2Qq1rA00Cc6F4bLG');
export async function navigateToPayment(lineItems) {
    const stripe = await stripePromise;
      await stripe.redirectToCheckout({
        lineItems: lineItems,
        mode: 'payment',
        successUrl: 'https://ccecouncil.com/',
        cancelUrl: 'https://ccecouncil.com/'
      });
}