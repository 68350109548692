import { getFirebaseDoc, setFirebaseDoc } from "../firebase-helper"

export function getHomeData(onLoaded) {
    getFirebaseDoc('infinite_scrolls', "home", data => {
       onLoaded(data)
    })
}

export function addHomeData(url, isVideo) {
    getHomeData(data => {
        data.media.push({
            url: url,
            isVideo: isVideo? isVideo: false
        })
        setFirebaseDoc("infinite_scrolls", "home", data)
    })
}

export function deleteByUrl(url, onDeleted) {
    getHomeData(data => {
        const resultMedia = [];
        data.media.forEach(m => {
            if (m.url !== url) {
                resultMedia.push(m)
            }
        })
        data.media = resultMedia;
        setFirebaseDoc("infinite_scrolls", "home", data, () => {
            onDeleted()
        })
    })

}