import React from 'react';
import './css/Calendar.css'
import {DayPilot, DayPilotMonth} from "@daypilot/daypilot-lite-react";
import "./css/MonthStyles.css";
function MonthlyCalendar({events}) {
  return (
    <div className='CalendarContainer'>
      <DayPilotMonth
        events={events}
        startDate={DayPilot.Date.today()}
        eventHeight={25}
        headerHeight={25}
        cellHeaderHeight={20}
        // ref={this.calendarRef}
      />
    </div>
  );
}

export default MonthlyCalendar;