import AppFrame from "../../Common/AppFrame";
import Button from "../../Common/Button";
import NewEventPopupInput from "../../Events/NewEventPopupInput";
import "./css/AdminPage.css";
import {signInWithEmailAndPassword, signOut} from "firebase/auth";
import {auth} from "../firebase-config";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

function AdminPage() {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const navigate = useNavigate();
    const login = () => {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // const user = userCredential.user;
                navigate('/')
            })
            .catch((error) => {
                // const errorCode = error.code;
                // const errorMessage = error.message;
            });
    }

    const logout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate('/')

        }).catch((error) => {
            // An error happened.
        });
    }

    return (
        <AppFrame>
            <div className="AdminPageContainer">
                <NewEventPopupInput label={"User"} onChange={setEmail}
                />
                <NewEventPopupInput label={"Password"} onChange={setPassword}
                />
                <Button label={"Login"} onClick={() => {
                    login()
                }}/>
                <Button label={"Logout"} onClick={() => {
                    logout()
                }}/>
            </div>
        </AppFrame>
    )
}

export default AdminPage;