import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './languages/en.json';
import fr from './languages/fr.json';
import ch from './languages/ch.json';

const resources = {
    en: {
        translation: en
    },
    fr: {
        translation: fr
    },
    ch: {
        translation: ch
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    })

export function addTranslation(data, key) {
    if (data?.en) {
        const lang = {}
        lang[key] = data.en;
        i18n.addResourceBundle('en', 'translation', lang, true, true)
    }
    if (data?.fr) {
        const lang = {}
        lang[key] = data.fr;
        i18n.addResourceBundle('fr', 'translation', lang, true, true)
    }
    if (data?.ch) {
        const lang = {}
        lang[key] = data.ch;
        i18n.addResourceBundle('ch', 'translation', lang, true, true)
    }
}