import '../../App.css';
import '../css/Event.css';
import AppFrame from '../../Common/AppFrame'
import EventPageInfo from './EventPageInfo';
import Banner from './Banner';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { getEventById } from '../../Firebase/Models/EventsModel';
import { useEffect, useState } from 'react';
import { printFirebaseDate } from '../../Tools/DateTool';
import { Timestamp } from 'firebase/firestore';
import { getLanguage } from '../../Localization/LanguageHelper';

function Event() {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {eventId} = useParams();
    const [event, setEvent] = useState({});
    const {i18n} = useTranslation();
    useEffect(() => {
        getEventById(eventId, data => {
            setEvent(data)
        })
    }, [eventId])

    const name = getLanguage(event.name,i18n);
    const language = getLanguage(event.language,i18n);
    const speakers = getLanguage(event.speakers,i18n);
    const content = getLanguage(event.description,i18n);
    const location = getLanguage(event.location,i18n);
    const imgUrl = event.url;

  return (
    <AppFrame>
        <div className='EventPage'>
            <Banner title={t("event_details")}/>
            <div className='EventImageSection'>
                <div className="EventPageImageContainer"
                    style={{
                        backgroundImage: "url(" + process.env.PUBLIC_URL + imgUrl + ")",
                    }}
                />
            </div>
            <div className='EventPageInfoWrapper'>
                <h3 className='PageDetailsTitle'>{name}</h3>
                <div className='EventPageInfoSection '>
                    <EventPageInfo title={t('language')} content={language} />
                    <EventPageInfo title={t('location')} content={location} />
                    <EventPageInfo title={t('date')} content={printFirebaseDate(event.date)}/>
                    <EventPageInfo title={t('duration')} content={event.duration} />
                    <EventPageInfo title={t('registration_deadline')} content={printFirebaseDate(event.deadline)} />
                    <EventPageInfo title={t('speakers')} content={speakers} />
                    <EventPageInfo title={t('description')} content={content} />
                </div>
                {event.deadline > Timestamp.now() && <div 
                    className='EventRegisterButton'
                    onClick={() => {
                        navigate(`/registration/${eventId}`)
                    }}
                    
                >
                    {t('register')}
                </div>}
            </div>
        </div> 
    </AppFrame>
  );
}

export default Event;
