import React from 'react';
import '../Events/css/NewEventPopup.css';
import NewEventPopupInput from '../Events/NewEventPopupInput';
import CIcon from '@coreui/icons-react';
import { cilCheckAlt } from '@coreui/icons';
import Dropdown from 'react-bootstrap/Dropdown';
import { useState } from 'react';
import {addMember} from '../Firebase/Models/MembersModel'
import PopupImageUploader from '../Common/PopupImageUploader';
import LanguageSelector from '../Common/LanguageSelector';
import { MemberTypes } from './MemberPage/Member';

function NewMemberPopup({close}) {
    const [language, setLanguage] = useState('en');
    const [type, setType] = useState(MemberTypes[0]);
    const [name, setName] = useState({});
    const [description, setDescription] = useState({})
    const [url, setUrl] = useState()
    const [currentDescriptionContent, setCurrentDescriptionContent] = useState(description[language]);
    let [currentNameContent, setCurrentNameContent] = useState(name[language]);

    const updateFields = (lang) => {
        setCurrentNameContent(name[lang] ? name[lang] : '')
        setCurrentDescriptionContent(description[lang] ? description[lang] : '')
    }
    return (
        <div 
            className="NewEventPopupContainer"
        >
        <LanguageSelector
            onSelect={(lang) => {
                updateFields(lang);
                setLanguage(lang)
            }}
        />
            <div className="NewEventContainer">
                <NewEventPopupInput label={'Name'} onChange={content => {
                    name[language] = content
                    setName(name)
                    setCurrentNameContent(content)
                }} value={currentNameContent}/>
                <PopupImageUploader label={'Image'} onUrlGenerated={setUrl}/>
                <NewEventPopupInput label={'Description'} value={currentDescriptionContent} onChange={content => {
                    description[language] = content
                    setDescription(description)
                    setCurrentDescriptionContent(content)
                }}/>
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {type}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {MemberTypes.map(m => {
                            return <Dropdown.Item onClick={() => {
                                setType(m)
                            }}>
                                {m}
                            </Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown>

                <div className='NewEventPopupFooter'>
                    <div className=''/> 
                    <div className='EventRegisterButton' onClick={() => {
                        addMember({
                            name: name,
                            description: description,
                            url: url,
                            type: type
                        })
                        close()
                        }}>
                        <CIcon icon={cilCheckAlt} size="sm" className='EventLocationSymbol'/>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default NewMemberPopup;