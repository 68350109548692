import {getFirebaseDoc, setFirebaseDoc} from "../firebase-helper";

const collection = "sponsors";
const domain = "footer";

export function getSponsors(onLoaded) {
    getFirebaseDoc(collection, domain, data => {
        onLoaded(data)
    })
}

export function addSponsor(sponsor) {
    getFirebaseDoc(collection, domain, data => {
        data.logos.push(sponsor)
        setFirebaseDoc(collection, domain, data)
    })
}

export function deleteSponsor(name) {
    getFirebaseDoc(collection, domain, data => {
        const result = [];
        data.logos.forEach(logo => {
            if (logo.name !== name) {
                result.push(logo);
            }
        })
        data.logos = result;
        setFirebaseDoc(collection, domain, data)
    })
}