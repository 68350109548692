import './css/Dropdown.css';
import { useNavigate } from "react-router-dom";

export default function SubDropdown({width, items, top}) {
    const navigate = useNavigate();
    const renderedItems = items ? items.map((item) =>
        <div
            key={item.label}
            className="DropdownItem"
            style={
                {width: width}
            }
            onClick={() => {
                if (item.linkTo) {
                    navigate(item.linkTo)
                }
            }}
        >
            <div className='DropdownLabel'>
                {item.label}
            </div>
        </div>
    ) : <></>;
    return (
        <div 
            className="SubDropdownContainer"
            style={{
                left: width,
                top: top
            }}
        >
            {renderedItems}
        </div>
    )
}