import '../App.css';
import AppFrame from '../Common/AppFrame';
//TODO delete this function and usage
function NewsDetails() {
    const imgUrl = '/Images/Events/Event.jpg';
  return (
    <AppFrame>
        <div className='EventPage'>
            <div className='EventImageSection'>
                <div className='PageSubTitle'>This is a sample news page</div>
                <div className="EventPageImageContainer"
                    style={{
                        backgroundImage: "url(" + process.env.PUBLIC_URL + imgUrl + ")",
                    }}
                />
            </div>
            <div className='EventDetailsSection'> 
                This is a sample description
            </div>
        </div>
    </AppFrame>
  );
}

export default NewsDetails;
