import '../App.css';
import Menu from '../Menu/Menu';
import Sponsors from '../Sponsors/Sponsors';
import Footer from '../Footer/Footer';

function AppFrame({children}) {
  return (
    <div className="App PageFont">
      <Menu/>
      <div className='AppContentSection'>
        {children}
      </div>
      <Footer>
        <Sponsors/>
      </Footer>
    </div>
  );
}

export default AppFrame;
