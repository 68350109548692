import './css/Arrow.css'
import './css/Accordion.css'
import { useState } from 'react';

function Accordion({label, children}) {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <div className="AccordionContainer">
      <div className='AccordionArrowContainer'
          onClick={() => {
            setCollapsed(!collapsed);
          }}>
        {label}
        <div 
          className='arrow down AccordionArrow'
        >

        </div>

      </div>
      {!collapsed && <div className='AccordionChildrenContainer'>
        {children}
      </div>}
    </div>
  );
}

export default Accordion;
