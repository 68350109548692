import './css/NewEventThumbnail.css';
import { cilLibraryAdd } from '@coreui/icons';
import NewEventPopup from './NewEventPopup';
import { useState } from 'react';
import LabeledIcon from '../Common/LabeledIcon';
function NewEventThumbnail() {
    const [showPopup, setShowPopup] = useState(false);
  return (
    <>
        <div 
            className="NewEventThumbnailContainer"
            onClick={() => {setShowPopup(true)}}
        >
          <LabeledIcon label={"Add Event"} iconName={cilLibraryAdd} />
        </div>
        {showPopup && <NewEventPopup 
            close={() => {
                setShowPopup(false)
            }}
        />}
    </>
    
  );
}

export default NewEventThumbnail;
