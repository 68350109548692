import { useState } from "react";
import AppFrame from "../Common/AppFrame";
import './css/AboutUs.css';
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { getAboutUsDescription } from "../Firebase/Models/DescriptionsModel";
import Banner from "../Events/EventPage/Banner";

import CIcon from '@coreui/icons-react';
import {cilPencil} from '@coreui/icons';
import AdminComponent from '../Firebase/Admin/AdminComponent';
import AboutUsEditPopup from "./AboutUsEditPopup";
import { getLanguage } from "../Localization/LanguageHelper";

function AboutUs() {
  const {t, i18n} = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  const [description, setDescription] = useState()
  useEffect(() => {
    getAboutUsDescription((data => {
        const result = getLanguage(data, i18n) 
        if (result) {
            setDescription({ __html: result})
        }
    }))
  }, [t, i18n])
  return (
    <AppFrame>
        <Banner title={t('about_us')} />
        <div className='EventImageSection'>
            <div className="EventPageImageContainer"
                style={{
                    backgroundImage: "url(/Images/Home/h13.jpg)",
                }}
            />
        </div>
        <div style={{display: "flex"}}>
            <div className="AboutUsContentContainer" dangerouslySetInnerHTML={description} />
            <AdminComponent>
                        <div className='HomePageEditButtonContainer'
                            onClick={() => {
                                setShowPopup(true)
                            }}
                        >
                            <CIcon icon={cilPencil} size="sm" className='EventLocationSymbol'/>
                        </div>
            </AdminComponent>
        </div>
        {showPopup && <AboutUsEditPopup
            close={() => {
                setShowPopup(false)
            }}
        />}
    </AppFrame>
  );
}

export default AboutUs;
