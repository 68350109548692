import '../css/Subscription.css';

function SubscriptionBullet({content}) {
  return (
    <div className='SubscriptionBulletPoint'>
        <div className='SubscriptionBulletDot' />
        <div className='SubscriptionBulletPointText'>{content}</div>
    </div>
  );
}

export default SubscriptionBullet;
