import './css/Footer.css';
import '../Common/css/text.css';
import '../Common/css/Splitters.css';
import '../Common/css/PureCSS.css';
import FooterInfo from './FooterInfo';


function Footer({children}) {
  return (
    <div className='FooterContainer'>
        {children}
        <FooterInfo/>
    </div>
  );
}

export default Footer;
