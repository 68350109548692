import Banner from '../Events/EventPage/Banner';
import './css/InnerPage.css';

function InnerPage({title, subtitle, children}) {
  return (
    <div className="InnerPageContainer">
        <Banner title={title} />
        <div className='InnerPageRightSection'>
        <div className='InnerPageSubTitle'>
            {subtitle}
          </div>
          {children}
        </div>
      </div>
  );
}

export default InnerPage;
