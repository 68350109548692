import {useEffect} from 'react';
import '../App.css';
import AppFrame from '../Common/AppFrame';
import {InfiniteScroll} from '../Common/InfiniteScroll';
import './css/Home.css';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {addTranslation} from '../Localization/i18n';
import CIcon from '@coreui/icons-react';
import {cilPencil} from '@coreui/icons';
import HomeEditPopup from './HomeEditPopup';
import {deleteByUrl, getHomeData} from '../Firebase/Models/InfiniteScrollModel';
import AdminComponent from '../Firebase/Admin/AdminComponent';
import {getHomeDescription} from "../Firebase/Models/DescriptionsModel";

function Home() {
    const [datas, setDatas] = useState([]);
    const {t} = useTranslation();
    const [showPopup, setShowPopup] = useState(false);
    const [description, setDescription] = useState({ __html: "" });
    useEffect(() => {
        getHomeData(data => {
            const cachedData = [];
            if (data?.media) {
                data.media.forEach((m) => {
                    cachedData.push({
                        isVideo: m.isVideo,
                        url: m.url,
                        delete: () => {
                            deleteByUrl(m.url, () => {
                                window.location.reload()
                            })
                        }
                    })
                })
            }
            setDatas(cachedData)
        })
        getHomeDescription(data => {
            addTranslation(data, 'home_description');
            setDescription({ __html: t("home_description") });
        })
    }, [t]);

    return (
        <AppFrame>
            <InfiniteScroll
                datas={datas}
            />
            <div className='HomePageContentContainer'>
                <div className="HomePageAboutUsContainer" dangerouslySetInnerHTML={description}/>
                <AdminComponent>
                    <div className='HomePageEditButtonContainer'
                            onClick={() => {
                                setShowPopup(true)
                            }}
                    >
                        <CIcon icon={cilPencil} size="sm" className='EventLocationSymbol'/>
                    </div>
                </AdminComponent>
                {showPopup && <HomeEditPopup
                    close={() => {
                        setShowPopup(false)
                    }}
                />}
            </div>
        </AppFrame>
    );
}

export default Home;
