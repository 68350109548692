import { doc, getDoc, setDoc, collection, getDocs, deleteDoc } from "firebase/firestore";
import { db } from "./firebase-config";

export function getFirebaseDoc(collection, docId, resolve, onFailure) {
    const scrollRef = doc(db, collection, docId);
    getDoc(scrollRef).then(docSnap => {
      if (docSnap.exists()) {
        resolve(docSnap.data())
      } else {
        onFailure()
      }
    }).catch((e) => {
        onFailure()
    })
}

export function setFirebaseDoc(collection, docId, data, resolve) {
    setDoc(doc(db, collection, docId), data)
    .then(resolve)
    .catch(e => {})
}

export function getAllCollectionDocs(collectionName, resolve) {
  getDocs(collection(db, collectionName)).then((snapshot) => {
    const result = []
    snapshot.docs.forEach(d => {
      result.push(d.data())
    })
    resolve(result)
  }).catch((e) => {
    
  })
}

export function deleteFirebaseDocById(id, collection) {
  deleteDoc(doc(db, collection, id))
    .then(() => {
        window.location.reload()
    })
    .catch((e) => {});
}