import './css/Footer.css';
import '../Common/css/text.css';
import '../Common/css/Splitters.css';
import '../Common/css/PureCSS.css';
import Hyperlink from '../Common/Hyperlink';
import AdminComponent from '../Firebase/Admin/AdminComponent';
import CIcon from '@coreui/icons-react';
import {cilPencil} from '@coreui/icons';
import { useState } from 'react';
import FooterEditPopup from './FooterEditPopup';
import { getLanguage } from '../Localization/LanguageHelper';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { getFooterDescription } from '../Firebase/Models/DescriptionsModel';

function FooterInfo() {
    const [showPopup, setShowPopup] = useState(false);
    const {i18n} = useTranslation();
    const [renderedDescription, setRenderedDescription] = useState();
    useEffect(() => {
        getFooterDescription(data => {
            const rendered = getLanguage(data, i18n).split("|").map(b => {
                return <div>{b}</div>
            })
            setRenderedDescription(rendered)
        })
    }, [i18n])

  return (
    <div className='FooterInfoContainer'>
        <div className='FooterHyperlinksContainer'>
            <Hyperlink imgUrl={"/Images/Logo/googleplus.png)"}/>
            <Hyperlink imgUrl={"/Images/Logo/facebook.png)"}/>
            <Hyperlink imgUrl={"/Images/Logo/twitter.png)"}/>
        </div>
        <AdminComponent>
            <div className='HomePageEditButtonContainer' style={{position: "unset"}}
                onClick={() => {
                    setShowPopup(true)
                }}
            >
                <CIcon icon={cilPencil} size="sm" className='EventLocationSymbol'/>
            </div>
        </AdminComponent>
        {renderedDescription}
        <div>© 2017-2023 Canadian Chinese Entrepreneurs Council. All Rights Reserved.</div>
        {showPopup && <FooterEditPopup
            close={() => {
                setShowPopup(false)
            }}
        />}
    </div>
  );
}

export default FooterInfo;
