import React, { useEffect, useRef } from 'react';
import './css/InfiniteScroll.css';
import './css/Arrow.css'
import CIcon from '@coreui/icons-react';
import { cilTriangle, cilTrash } from '@coreui/icons';
import ReactPlayer from 'react-player';
import AdminComponent from '../Firebase/Admin/AdminComponent';
import { useMediaQuery } from 'react-responsive';
import { mobileScreen } from '../App';

var manuallyScrolling = false;
var videoPlaying = false;

function scroll(element, movingForward, distance, manual, delta) {
    if (!element) {
        return
    }
    let scrollAmount = 0;
    if (manual) {
        manuallyScrolling = true;
    }
    var slideTimer = setInterval(() => {
        if (videoPlaying && !manual) {
            return
        }
        if(movingForward) {
            element.scrollLeft += delta;
        } else {
            element.scrollLeft -= delta;
        }
        scrollAmount += delta;
        if(scrollAmount >= distance || (!manual && manuallyScrolling)) {
            window.clearInterval(slideTimer);
            if (manual) {
                manuallyScrolling = false;
            }
        }
    }, 10);
}

function ScrollItem({data}) {
    const renderedContent = data.isVideo ? <ReactPlayer width={"490px"}
     height={'330px'} url={"https://www.youtube.com/watch?v=" + data.url} onPlay={() => {
        videoPlaying = true;
     }} onPause={() => {
        videoPlaying = false;
     }} onEnded={() => {
        videoPlaying = false;
     }}
     
    /> :<div className="HomePageImageContainer"
    style={{
        backgroundImage: "url(" + process.env.PUBLIC_URL + data.url + ")",
    }}
/>;
    return (
        <div className={"ScrollItemContainer"}>
            <AdminComponent>
                <div className='DeleteButton'
                    onClick={() => {
                        data.delete()
                    }}
                >
                    <CIcon icon={cilTrash} size="custom" className=''/>
                </div>
            </AdminComponent>
            {renderedContent}
        </div>
    );
}

export function InfiniteScroll({datas, hideControls, height}) {
    const isMobile = useMediaQuery({
        query: mobileScreen
    })
    const itemWidth = 510;
    const itemCount = datas.length;
    const handleScroll = (e) => {
        const container = e.currentTarget;
        let scrollPos = container.scrollLeft;
        let clientWidth = container.clientWidth;
        let scrollWidth = container.scrollWidth;
        
        if (Math.ceil(scrollPos + clientWidth) >= scrollWidth) {
            setScroll(container, itemCount*itemWidth-clientWidth);
        } else if (scrollPos <= 0) {
            setScroll(container, itemCount*itemWidth);
        }
    } 

    const setScroll = (element, pos) => {
        element.scrollLeft = pos;
    }
      
    const scrollRight = (e) => {
        scroll(e.currentTarget.previousSibling, true, itemWidth, true, 10);
    }
      
    const scrollLeft = (e) => {
        scroll(e.currentTarget.nextSibling, false, itemWidth, true, 10);
    }

    const renderedItems = datas.map((data, index) => {
        return <ScrollItem key={index} data={data}/>
    })

    const renderedCopies = datas.map((data, index) => {
        return <ScrollItem key={"copy" + index} data={data}/>
    })

    const scrollable = useRef(null);
 

    useEffect(() => {
        if (isMobile) {
            return
        }
        setTimeout(() => {
            scroll(scrollable.current, true, itemWidth/2, false, 1)
        }, 1000);
        const interval = setInterval(() => {
            scroll(scrollable.current, true, itemWidth/1.8, false, 1)
        }, 5000);
        return () => {clearInterval(interval)}
    }, [isMobile])

    return (
        <div className="InfiniteScrollContainer">
            {(!hideControls || isMobile) && <div className='ScrollLeftButton ScrollButton' onClick={scrollLeft}>
                <CIcon icon={cilTriangle} size="sm" className='ScrollLeft ScrollIcon'/>
            </div>}
            <div ref={scrollable} className="ScrollableComponent" onScroll={handleScroll}>
                {renderedItems}
                {renderedCopies}
            </div>
            {(!hideControls || isMobile) && <div className='ScrollRightButton ScrollButton' onClick={scrollRight}>
                <CIcon icon={cilTriangle} size="sm" className='ScrollRight ScrollIcon'/>
            </div>}
        </div>
    )
}