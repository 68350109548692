import './css/News.css';

function LargeNewsThumbnail({name, description}) {
  return (
    <div 
        className='LargeNewsThumbnailContainer'
        onClick={() => {
        }}      
    >
        <div className='NewsName NewsNameContainer LargeNewsNameContainer' >
            {name}
        </div>
        <div className='LargeNewsDescriptionContainer' dangerouslySetInnerHTML={{ __html: description}} />
    </div>
  );
}

export default LargeNewsThumbnail;
