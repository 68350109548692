import MemberThumbnail from './MemberThumbnail';
import './css/MemberList.css'
import '../Common/css/text.css'
import Banner from '../Events/EventPage/Banner';
import AppFrame from '../Common/AppFrame';
import NewMemberThumbnail from './NewMemberThumbnail';
import { useParams } from 'react-router-dom';
import AdminComponent from '../Firebase/Admin/AdminComponent';
import { useEffect } from 'react';
import { getAllMembers } from '../Firebase/Models/MembersModel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MemberTypes } from './MemberPage/Member';
import { getLanguage } from '../Localization/LanguageHelper';
function MemberList() {
    const {t, i18n } = useTranslation()
    const {filter} = useParams();
    const [members, setMembers] = useState([])
    useEffect(() => {
        getAllMembers(data => {
            setMembers(data)
        });
    }, [])
    const renderIcons = (type) => {
        return members.filter(m => m.type === type).map(m => {
            return <MemberThumbnail key={m.id} imgUrl={m.url} name={getLanguage(m.name, i18n)}
            description={getLanguage(m.description, i18n)} id={m.id}/>
        })
    }
    const renderedMembersList = MemberTypes.map((memberType) => {
        const members = renderIcons(memberType)
        return <>
         {(filter === memberType || filter === 'all') && members.length > 0 && 
        <>
            <Banner title={t(memberType)}/>
            <div className='MemberListContainer'>
                {members}
            </div>
        </>
        }
        </>
    })
  return (
    <AppFrame>
    <div className="MembersSectionContainer">
        <AdminComponent>
            <NewMemberThumbnail />
        </AdminComponent>
        {renderedMembersList}
    </div>
    </AppFrame>
  );
}

export default MemberList;
