import React from 'react';
import './css/LabeledIcon.css';
import CIcon from '@coreui/icons-react';

function LabeledIcon({label, iconName, onClick}) {

    return (
        <div>
            <CIcon icon={iconName} size="sm" className='LabeledIcon'/>
            <div className='LabeledIconLabel'> 
                {label}
            </div>
        </div>
    );

}

export default LabeledIcon;