import React, { useState } from 'react';
import './css/LangaugeSelector.css'
import { Languages } from '../Menu/LanguageSwitch';
function LanguageSelector({onSelect}) {
    const [selected, setSelected] = useState('en');
    
    const tabs = Languages.map(tab => {
        return <div 
            key={tab.symbol}
            className={'LangaugeSelectorTab' + (selected === tab.symbol ? ' LangaugeSelectorTabSelected' : '')}
            onClick={() => {
                setSelected(tab.symbol)
                onSelect(tab.symbol)
            }}
        
        >
        {tab.symbol}
    </div> 
    })
    return (
        <div className="LangaugeSelectorContainer">
            {tabs}
        </div>
    );

}

export default LanguageSelector;