import { useEffect, useState } from 'react';
import '../../App.css';
import '../css/Event.css';
import '../css/Registration.css'
import AppFrame from '../../Common/AppFrame'
import Banner from './Banner';
import React from 'react';
import EventTicket from './EventTicket';
import { navigateToPayment } from '../../Payment/Stripe';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getEventById } from '../../Firebase/Models/EventsModel';
import PaymentDetails from './PaymentDetails';
import { printFirebaseDate } from '../../Tools/DateTool';
import { getLanguage } from '../../Localization/LanguageHelper';

function EventRegistration() {
    const {t} = useTranslation();
    const [nonMemberQuantity, setNonMemberQuantity] = useState(0);
    const [memberQuantity, setMemberQuantity] = useState(0);
    const {eventId} = useParams();
    const [event, setEvent] = useState({});
    const {i18n} = useTranslation();
    useEffect(() => {
        getEventById(eventId, setEvent)
    }, [eventId])
    function submit() {
        const lineItems = [];
        if (nonMemberQuantity > 0 && event && event.priceUrl) {
            lineItems.push({
                price: event.priceUrl, 
                quantity: nonMemberQuantity,
            })
        }
        if (memberQuantity > 0 && event && event.memberPriceUrl) {
            lineItems.push({
                price: event.memberPriceUrl, 
                quantity: memberQuantity,
            })
        }
        if (lineItems.length > 0) {
            navigateToPayment(lineItems)
        }
    }

    
    const name = getLanguage(event.name, i18n);
    const location = getLanguage(event.location, i18n);

  return (
    <AppFrame>
      <Banner title={t("event_registration")} />
      <div className='RegistrationContentContainer'>
        <PaymentDetails 
            EventName={name} 
            EventDate={printFirebaseDate(event.date)} 
            EventLocation={location}
            MemberQuantity={memberQuantity}
            nonMemberQuantity={nonMemberQuantity}
            MemberPrice={event.memberPrice}
            nonMemberPrice={event.price}
        />
        <EventTicket 
            name={name}
            memberQuantity={memberQuantity}
            nonMemberQuantity={nonMemberQuantity}
            incrementMember={() => {
                setMemberQuantity(memberQuantity + 1)
            }} 

            incrementNonMember={() => {
                setNonMemberQuantity(nonMemberQuantity + 1)
            }}
            
            decrementMember={() => {
                if (memberQuantity > 0) {
                    setMemberQuantity(memberQuantity - 1)                
                }
            }} 
            decrementNonMember={() => {
                if (nonMemberQuantity > 0) {
                    setNonMemberQuantity(nonMemberQuantity - 1)                
                }

            }}
            submit={submit} 
            memberPrice={event.memberPrice}
            nonMemberPrice={event.price}
        />
      </div>
    </AppFrame>
  );
}

export default EventRegistration;
