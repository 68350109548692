import '../../App.css';
import '../css/Subscription.css';
import AppFrame from '../../Common/AppFrame';
import Subscription from './Subscription';
import { useEffect } from 'react';
import { getAllSubscriptions } from '../../Firebase/Models/SubscriptionsModel';
import { useState } from 'react';
import Banner from '../../Events/EventPage/Banner';
import { useTranslation } from 'react-i18next';
import Button from '../../Common/Button';
import LabeledIcon from '../../Common/LabeledIcon';
import AdminComponent from '../../Firebase/Admin/AdminComponent';
import { cilLibraryAdd } from '@coreui/icons';
import AddSubscriptionPopup from './AddSubscriptionPopup';
import { getLanguage } from '../../Localization/LanguageHelper';

function BecomeAMember() {
    const {i18n, t} = useTranslation();
    const [subscriptions, setSubscriptions] = useState([])
    const [showPopup, setShowPopup] = useState(false);
    useEffect(() => {
        getAllSubscriptions(setSubscriptions)
    }, [])

    const renderedSubscriptions = subscriptions.map(s => {
        return <Subscription id={s.id} title={getLanguage(s.name, i18n)} price={s.price} 
        benefits={getLanguage(s.benefits, i18n)} 
        description={getLanguage(s.description,i18n)} priceUrl={s.priceUrl}/>
    })
  return (
    <AppFrame>
        <Banner title={t('become_a_member')} />
      <div className='BecomeAMemberInformationContainer'>
        <div>
          1.Download this pdf from the link below. <br/>
          2.Fill this form and send it back to info@ccecouncil.org. <br/>
          3.We will contact you with an invitation code. <br/>
        </div>
        <Button label={t('Download PDF')} onClick={() => {
          fetch('/pdf/registration.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'CCEC_Registration.pdf';
                alink.click();
            })
        })
        }}/>
      </div>
      <div className="SubscriptionsContainer">
         {renderedSubscriptions}
      </div>
      
        <AdminComponent>
            <div
                className=''
                onClick={() => {setShowPopup(true)}}
            >
                <LabeledIcon label={"Add Subscription"} iconName={cilLibraryAdd} />
            </div>
        </AdminComponent>
        {showPopup && <AddSubscriptionPopup 
            close={() => {
                setShowPopup(false)
            }}
        />}
    </AppFrame>
  );
}

export default BecomeAMember;
