import React from 'react';
import ImageUploading from 'react-images-uploading';
import { useState } from 'react';
import './css/PopupImageUploader.css'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../Firebase/firebase-config';

function PopupImageUploader({label, onUrlGenerated}) {
    const [images, setImages] = useState([]);
    const maxNumber = 69;
  
    const onChange = (imageList, addUpdateIndex) => {
      console.log(imageList, addUpdateIndex);
      setImages(imageList);
      addUpdateIndex.forEach(index => {
        const image = imageList[index];
        const file = image.file;
        const storageRef = ref(storage, `/files/${file.name}`)
        uploadBytes(storageRef, file).then((snapshot) => {
            getDownloadURL(storageRef).then((url) => {
                onUrlGenerated(url)
            }).catch((e) => {
                console.log(e)
            })
        }).catch(() => {

        });
      })
    };
    return (
        <div className="PopupImageUploaderContainer">
            <div className='PopupImageUploaderTitleContainer'>
                {label}
            </div> 
            <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
            >
                {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
                }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                    <button
                    style={isDragging ? { color: 'red' } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                    >
                    Click to Upload
                    </button>
                    &nbsp;
                    {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                    {imageList.map((image, index) => (
                    <div key={index} className="image-item">
                        <img src={image['data_url']} alt="" width="100" />
                        <div className="image-item__btn-wrapper">
                        {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                        {/* <button onClick={() => onImageRemove(index)}>Remove</button> */}
                        </div>
                    </div>
                    ))}
                </div>
                )}
            </ImageUploading>
            
        </div>
    );

}

export default PopupImageUploader;