import React from 'react';
import './css/Input.css';
function Input({value, onChange, disabled, style}) {
    return (
        <div className="InputContainer" style={style}>
            <input 
                value={value}
                onChange={(e) => {
                    onChange(e.target.value)
                }}
                style={{width: '100%'}}
                disabled={disabled}
            />
        </div>
    );

}

export default Input;