import { useTranslation } from 'react-i18next';
import '../../App.css';
import '../css/Event.css';
import '../css/Registration.css';
import EventPrice from './EventPrice';

function EventTicket({name, memberQuantity, nonMemberQuantity, 
    incrementMember, decrementMember, incrementNonMember, decrementNonMember, 
    submit, memberPrice, nonMemberPrice}) {
    const {t} = useTranslation();
  return (
    <div className='RegistrationInfoContainer'>
         <EventPrice eventName={name} 
                        memberPrice={memberPrice} 
                        nonMemberPrice={nonMemberPrice}
                        MemberQuantity={memberQuantity}
                        nonMemberQuantity={nonMemberQuantity}
                        incrementMember={incrementMember}
                        decrementMember={decrementMember}
                        incrementNonMember={incrementNonMember}
                        decrementNonMember={decrementNonMember}
                        />
        <div className='RegistrationButtonContainer'>
            <div className='FormButton'
                    onClick={() => {
                        submit();
                    }}
                >
                    {t("register")}
            </div>
        </div>
    </div>
  );
}

export default EventTicket;
