import { useMediaQuery } from 'react-responsive';
import { mobileScreen } from '../../App';
import '../css/Banner.css';

function Banner ({title}) {
    const isMobile = useMediaQuery({
        query: mobileScreen
    })
    const defaultCharacterSize = isMobile ? 24 : 65;
    let fontSize = (defaultCharacterSize - Math.max(title.length-15, 0));
    fontSize = Math.max(fontSize, 12)
    console.log(fontSize)
    return (
        <div className='EventPageBannerContainer'>
        <div className='EventPageBanner'>
            <div 
                className='EventPageBannerTitle'
                style={{
                    fontSize: fontSize + "px"
                }}
            >
                {title}
            </div>
        </div>
    </div>
    );
}

export default Banner;