import CIcon from '@coreui/icons-react';
import './css/Menu.css';
import { cilLanguage } from '@coreui/icons';
import Dropdown from '../Common/Dropdown';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { mobileScreen } from '../App';
import MenuItem from './MenuItem';

export const Languages = [
  {
    label: "中文",
    symbol: "ch"
  }, {
    label: "Français",
    symbol: "fr"
  }, {
    label: "English",
    symbol: "en"
  }
]

function LanguageSwitch() {
  const isMobile = useMediaQuery({
    query: mobileScreen
  });
  const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false);
  const {t, i18n } = useTranslation()

  const [currentLanguage, setCurrentLanguage] = useState("en")

  const items = []

  Languages.forEach(language => {
    if (currentLanguage !== language.symbol) {
      items.push({
        label: language.label,
        onClick: () => {
          i18n.changeLanguage(language.symbol)
          setCurrentLanguage(language.symbol)
        }
      })
    }
  });

  return (
    <div className="LanguageSwitch"
    
      onMouseOver={() => setMenuDropDownOpen(true)}
      onMouseOut={() => setMenuDropDownOpen(false)}
    >
      {!(isMobile && isMenuDropDownOpen) && <CIcon icon={cilLanguage} size="sm" className='MenuLanguageSymbol'/>}
      {t('language')}
      {!isMobile && isMenuDropDownOpen && 
          <Dropdown items={items}
            width={(4*7.565+40)*2}
          />
      }
      {isMobile && isMenuDropDownOpen && items && items.map(item => {
        return <MenuItem title={item.label} onClick={item.onClick}/>
      })}
    </div>
  );
}

export default LanguageSwitch;
