import AppFrame from '../Common/AppFrame';
import NewsThumbnail from './NewsThumbnail';
import './css/News.css';
import AddNewsThumbnail from './AddNewsThumbnail';
import LargeNewsThumbnail from './LargeNewsThumbnail';
import { useState } from 'react';
import { InfiniteScroll } from '../Common/InfiniteScroll';
import AdminComponent from '../Firebase/Admin/AdminComponent';
import { useEffect } from 'react';
import { getAllNews } from '../Firebase/Models/NewsModel';
import { useTranslation } from 'react-i18next';
import { printFirebaseDate } from '../Tools/DateTool';
import { getLanguage } from '../Localization/LanguageHelper';

function NewsPage() {
  const [showLeftColumnScroll, setShowLeftColumnScroll] = useState(false);
  const [showRightColumnScroll, setshowRightColumnScroll] = useState(false);
  const {i18n} = useTranslation();
  const [newsList, setNewsList] = useState([]);
  const [selectedNews, setSelectedNews] = useState();
  useEffect(() => {
    getAllNews(allNews => {
      setNewsList(allNews)
      if (allNews?.length > 0) {
        setSelectedNews(allNews[0])
      }
    })
  }, [])
  
  const renderedThumbnails = newsList.map(news => {
    return <NewsThumbnail key={news.id} name={getLanguage(news.title, i18n)} id={news.id}
    imgUrl={news.urls[0]} onClick={() => {
      setSelectedNews(news)
    }} date={printFirebaseDate(news.date)} location={getLanguage(news.location, i18n)} />
  })

  const inifiniteScrollData = selectedNews ? selectedNews.urls.map(url => {
    return {
      url: url
    }
  }) : [];
  return (
    <AppFrame>
        <div className='NewsPageContainer'>
          <div className='NewsColumn'
            style={{
              overflowY: showLeftColumnScroll ? "overlay" : "hidden"
            }}
            onMouseOver={() => {
              setShowLeftColumnScroll(true)
            }}
            onMouseOut={() => {
              setShowLeftColumnScroll(false)
            }}
          >
            {renderedThumbnails}
            <AdminComponent>
              <AddNewsThumbnail />
            </AdminComponent>
          </div>
          <div className='NewsColumn RightColumn' 
            style={{
              overflowY: showRightColumnScroll ? "overlay" : "hidden"
            }}
            onMouseOver={() => {
              setshowRightColumnScroll(true)
            }}
            onMouseOut={() => {
              setshowRightColumnScroll(false)
            }}>
              <InfiniteScroll 
              datas={inifiniteScrollData}
            hideControls={true}
            />
            {selectedNews &&<LargeNewsThumbnail 
              name={getLanguage(selectedNews.title, i18n)} 
              description={getLanguage(selectedNews.description, i18n)}
            />}
          </div>
        </div>
    </AppFrame>
  );
}

export default NewsPage;
