import './css/Menu.css';
import Dropdown from '../Common/Dropdown';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { mobileScreen } from '../App';

function MenuItem({title, linkTo, onClick, items}) {
  const isMobile = useMediaQuery({
    query: mobileScreen
  });
  const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false);
  const navigate = useNavigate();
  const width = Math.max((title.length*7.565+40)*2, 150);
  return (
      <div 
        className="MenuItem"
        onMouseOver={() => setMenuDropDownOpen(true)}
        onMouseOut={() => setMenuDropDownOpen(false)}
      >
        <div className='MenuItemLabelContainer'>
          <div
            className='MenuItemLink'
            onClick={() => {
              if (linkTo) {
                  navigate(linkTo)
              }
              if (onClick) {
                onClick()
              }
            }}
          >
            {title}
          </div>
          {isMobile && items && <div 
            className={'arrow MenuArrowDown ' + (isMenuDropDownOpen ? 'up' : 'down')}
            onClick={() => {
              setMenuDropDownOpen(!isMenuDropDownOpen)
            }}
          />}

        </div>
          {!isMobile && isMenuDropDownOpen && 
            <Dropdown items={items}
              width={width}
            />
          }
          {isMobile && isMenuDropDownOpen && items && items.map(item => {
            return <MenuItem title={item.label} linkTo={item.linkTo}/>
          })}
      </div>
  );
}

export default MenuItem;
