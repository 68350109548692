import {deleteFirebaseDocById, getAllCollectionDocs, getFirebaseDoc, setFirebaseDoc} from "../firebase-helper";
import { v4 as uuidv4 } from 'uuid';

const collection = "members"

export function getAllMembers(onLoaded) {
    getAllCollectionDocs(collection, data => {
        onLoaded(data)
    })
}

export function getMemberById(id, onLoaded, onFailure) {
    getFirebaseDoc(collection, id, onLoaded, onFailure)
}

export function addMember(member) {
    const id = uuidv4();
    setFirebaseDoc(collection, id, {
        id: id,
        name: member.name,
        type: member.type,
        description: member.description,
        url: member.url
    })
}

export function deleteMember(id) {
    deleteFirebaseDocById(id, collection)
}