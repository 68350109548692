import React from 'react';
import { useState } from 'react';
import './css/PopupImageUploader.css'
import Input from './Input';
import Button from './Button';

function PopupVideoUploader({title, onUrlGenerated}) {
    const [videos, setVideos] = useState([]);
    const [cursor, setCursor] = useState();

    return (
        <div className="PopupImageUploaderContainer">
            <div className='PopupImageUploaderTitleContainer'>
                {title}
            </div> 
            {videos.map(video => {
                return <div> 
                    {video}
                </div>
            })}
            <Input onChange={(content) => {
                setCursor(content)
            }} value={cursor}/>
            <Button onClick={() => {
                onUrlGenerated(cursor)
                const tmp = videos;
                tmp.push(cursor)
                setVideos(tmp)
                setCursor('')
            }} 
                width={"50px"}
                height={"20px"}
                label={"Upload"}
            />
        </div>
    );

}

export default PopupVideoUploader;