import './css/CharterBulletPoints.css'

function CharterBulletPoints({bulletPoints, prefix}) {
    const renderItems = bulletPoints ? bulletPoints.map((item, index) => 
        <div className='BulletPoint'>{prefix}{index+1}. {item}</div>
    ): <></>;
  return (
    <div className="CharterBulletPointsContainer">
        {renderItems}
    </div>
  );
}

export default CharterBulletPoints;
