import './css/News.css';
import { cilLibraryAdd } from '@coreui/icons';
import LabeledIcon from '../Common/LabeledIcon';
import AddNewsPopup from './AddNewsPopup';
import { useState } from 'react';
import AdminComponent from '../Firebase/Admin/AdminComponent';

function AddNewsThumbnail({name, imgUrl}) {
    const [showPopup, setShowPopup] = useState(false);
  return (
    <>
      <div 
          className='NewsThumbnailContainer'
          style={{
              display: "flex",
              justifyContent: "center"
          }}   
          onClick={() => {setShowPopup(true)}}
      >
        <AdminComponent>
          <LabeledIcon label={"Add News"} iconName={cilLibraryAdd} />
        </AdminComponent>
      </div>
        {showPopup && <AddNewsPopup 
            close={() => {
                setShowPopup(false)
            }}
        />}
    </>
  );
}

export default AddNewsThumbnail;
