import AdminComponent from '../Firebase/Admin/AdminComponent';
import { deleteSponsor } from '../Firebase/Models/SponsorsModel';
import './css/Sponsors.css';
import CIcon from '@coreui/icons-react';
import {cilTrash } from '@coreui/icons';

function Sponsor({imgUrl, hyperlink, name}) {
  return (
    <div className='SponsorContainerParent'>
        <AdminComponent>
            <div className='DeleteButton'
                onClick={() => {
                    deleteSponsor(name)
                }}
            >
                <CIcon icon={cilTrash} size="sm" className=''/>
            </div>
        </AdminComponent>
        <div 
            className='SponsorContainer'
            onClick={() => {
            window.open(hyperlink, "_blank")
            }}
        >
            <img className="SponsorImageContainer"
                alt=""
                src={process.env.PUBLIC_URL + imgUrl}
            />
        </div>
    </div>
  );
}

export default Sponsor;
