export function printFirebaseDate(firebaseDate) {
    if (!firebaseDate) {
        return ''
    }
    return new Date(firebaseDate.seconds * 1000).toLocaleString("en-US");
}

export function printIOSDate(firebaseDate) {
    if (!firebaseDate) {
        return ''
    }
    const date = new Date(firebaseDate.seconds * 1000);
    return date.toISOString().split('T')[0];
}