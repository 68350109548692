import React, {useEffect, useState} from 'react';
import '../Events/css/NewEventPopup.css';
import CIcon from '@coreui/icons-react';
import { cilCheckAlt } from '@coreui/icons';
import {getFooterDescription, setFooterDescription} from "../Firebase/Models/DescriptionsModel";
import Input from '../Common/Input';

export default function FooterEditPopup({close}) {
    const [description, setDescription] = useState({})
    useEffect(() => {
        getFooterDescription(data => {
            setDescription(data)
        })
    }, [])


    return (
        <div 
            className="NewEventPopupContainer"
        >

            <div className="NewEventContainer">
                <Input
                    label={'Description'} disableImage={true}
                    value={description["en"]}
                    onChange={(content) => {
                        setDescription(content)
                    }}
                    style={{width: "100%"}}
                />

                <div className='NewEventPopupFooter'>
                    <div className='EventRegisterButton' onClick={() => {
                        setFooterDescription({
                            "en": description
                        })
                        close()
                    }}>
                        <CIcon icon={cilCheckAlt} size="sm" className='EventLocationSymbol'/>
                    </div>
                </div>
            </div>
        </div>
    );

}