import {deleteFirebaseDocById, getAllCollectionDocs, setFirebaseDoc} from "../firebase-helper";
import { v4 as uuidv4 } from 'uuid';

const collection = "news"

export function getAllNews(onLoaded) {
    getAllCollectionDocs(collection, data => {
        onLoaded(data)
    })
}

export function addNews(news) {
    const id = uuidv4();
    setFirebaseDoc(collection, id, {
        id: id,
        title: news.title,
        location: news.location,
        date: news.date,
        description: news.description,
        urls: news.urls
    })
}

export function deleteNews(id) {
    deleteFirebaseDocById(id, collection)
}