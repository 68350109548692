import './css/Sponsors.css';
import '../Common/css/text.css';
import '../Common/css/Splitters.css';
import '../Common/css/PureCSS.css';

import Sponsor from './Sponsor';
import LabeledIcon from '../Common/LabeledIcon';
import { cilLibraryAdd } from '@coreui/icons';
import AddSponsorPopup from './AddSponsorPopup';
import { useState } from 'react';
import AdminComponent from '../Firebase/Admin/AdminComponent';
import { getSponsors } from '../Firebase/Models/SponsorsModel';
import { useEffect } from 'react';

function Sponsors() {
    const [showPopup, setShowPopup] = useState(false);
    const [sponsors, setSponsors] = useState([]);

    useEffect(() => {
        getSponsors(data => {
            setSponsors(data.logos)
        })
    }, [])

    const renderedSponsors = sponsors.map(sponsor => {
        return <Sponsor key={sponsor.name} imgUrl={sponsor.url} hyperlink={sponsor.link} name={sponsor.name} />
    })
    const year = new Date().getFullYear();
    const nextYear = year + 1;
  return (
    <>
        <div className='SponsorsContainer'>
            <div className='PageSubTitle SponsorYearContainer'>
                {year + "-" + nextYear}
            </div>
            <div className='SponsorsListContainer'>
                {renderedSponsors}
                <AdminComponent>
                    <div
                        className='AddSponsor'
                        onClick={() => {setShowPopup(true)}}
                    >
                        <LabeledIcon label={"Add Sponsor"} iconName={cilLibraryAdd} />
                    </div>
                </AdminComponent>
            </div>
        </div>
        {showPopup && <AddSponsorPopup 
            close={() => {
                setShowPopup(false)
            }}
        />}
    </>
  );
}

export default Sponsors;
