import React, {useEffect, useState} from 'react';
import '../Events/css/NewEventPopup.css';
import CIcon from '@coreui/icons-react';
import { cilCheckAlt } from '@coreui/icons';
import PopupEditor from '../Common/PopupEditor';
// import PopupImageUploader from '../Common/PopupImageUploader';
import LanguageSelector from '../Common/LanguageSelector';
import {getAboutUsDescription, setAboutUsDescription} from "../Firebase/Models/DescriptionsModel";

export default function AboutUsEditPopup({close}) {
    const [language, setLanguage] = useState('en');
    const [description, setDescription] = useState({})
    let currentEditorContent = description[language];

    useEffect(() => {
        getAboutUsDescription(data => {
            setDescription(data)
        })
    }, [])

    const updateDescription = () => {
        const tmp = description;
        tmp[language] = currentEditorContent;
        setDescription(tmp)
    }

    return (
        <div 
            className="NewEventPopupContainer"
        >
            <LanguageSelector
                onSelect={(lang) => {
                    updateDescription()
                    setLanguage(lang)
                }}
            />
            <div className="NewEventContainer">
                {/* <PopupImageUploader label={'Poster'} 
                    onUrlGenerated={url => {
                    }}
                />
                 */}
                <PopupEditor
                    label={'Description'} disableImage={true}
                    value={description[language]}
                    onChange={(content) => {
                        currentEditorContent=content;
                    }}
                />

                <div className='NewEventPopupFooter'>
                    <div className='EventRegisterButton' onClick={() => {
                        const tmp = description;
                        tmp[language] = currentEditorContent;
                        setDescription(tmp)
                        setAboutUsDescription(tmp)
                        close()
                    }}>
                        <CIcon icon={cilCheckAlt} size="sm" className='EventLocationSymbol'/>
                    </div>
                </div>
            </div>
        </div>
    );

}