import React from 'react';
import CIcon from '@coreui/icons-react';
import { cilCheckAlt } from '@coreui/icons';
import { useState } from 'react';
import { addSubscription } from '../../Firebase/Models/SubscriptionsModel';
import LanguageSelector from '../../Common/LanguageSelector';
import NewEventPopupInput from '../../Events/NewEventPopupInput';

export default function AddSubscriptionPopup({close}) {
    const [language, setLanguage] = useState('en');
    const [name, setName] = useState({});
    const [description, setDescription] = useState({})
    const [price, setPrice] = useState();
    const [priceUrl, setPriceUrl] = useState();
    const [benefits, setBenefits] = useState({})

    const [currentNameContent, setCurrentNameContent] = useState(name[language]);
    const [currentBenefitsContent, setCurrentBenefitsContent] = useState(benefits[language]);
    const [currentDescriptionContent, setCurrentDescriptionContent] = useState(description[language]);

    const updateFields = (lang) => {
        setCurrentNameContent(name[lang] ? name[lang] : '')
        setCurrentBenefitsContent(benefits[lang] ? benefits[lang] : '')
        setCurrentDescriptionContent(description[lang] ? description[lang] : '')
    }

    return (
        <div 
            className="NewEventPopupContainer"
        >
            <LanguageSelector
                onSelect={(lang) => {
                    updateFields(lang);
                    setLanguage(lang)
                }}
            />
            <div className="NewEventContainer">
                <NewEventPopupInput label={'Name'} onChange={content => {
                    name[language] = content;
                    setName(name)
                    setCurrentNameContent(content)
                }} value={currentNameContent}/>
                <NewEventPopupInput label={'Price'} onChange={setPrice} value={price}/>
                <NewEventPopupInput label={'Price Url'} onChange={setPriceUrl} value={priceUrl}/>
                <NewEventPopupInput label={'Description'} value={currentDescriptionContent} onChange={content => {
                    description[language] = content
                    setDescription(description)
                    setCurrentDescriptionContent(content)
                }}/>
                <NewEventPopupInput label={'Benefits'} onChange={content => {
                    benefits[language] = content;
                    setBenefits(benefits)
                    setCurrentBenefitsContent(content)
                }} value={currentBenefitsContent}/>

                <div className='NewEventPopupFooter'>
                    <div className=''> 

                    </div>

                    <div className='EventRegisterButton' onClick={() => {
                        addSubscription({
                            name,
                            price,
                            benefits,
                            description,
                            priceUrl
                        })
                        close()
                        }}>
                        <CIcon icon={cilCheckAlt} size="sm" className='EventLocationSymbol'/>
                    </div>
                </div>
            </div>
        </div>
    );

}