import AdminComponent from '../Firebase/Admin/AdminComponent';
import { deleteNews } from '../Firebase/Models/NewsModel';
import './css/NewsThumbnail.css';
import { cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

function NewsThumbnail({name, imgUrl, onClick, date, location, id}) {
  return (
    <div 
        className='NewsThumbnailContainer'
        onClick={onClick}      
    >
        <AdminComponent>
                    <div className='DeleteButton'
                        onClick={(e) => {
                            e.stopPropagation();
                            deleteNews(id)
                        }}
                    >
                        <CIcon icon={cilTrash} size="custom" className=''/>
                    </div>
        </AdminComponent>
        <div className="NewsThumbnailImageContainer"
            style={{
                backgroundImage: "url(" + process.env.PUBLIC_URL + imgUrl + ")",
            }}
        />
        <div className='NewsThumnailContentContainer'>
            <div className='NewsThumnailContentUpperSection'>
                <div className='NewsThumbnailName' >
                    {name}
                </div>
                <div className='NewsThumbnailInformationSection'>
                    <div className='NewsThumbnailDate'>
                        {date}
                    </div>
                    <div className='NewsThumbnailDate'>
                        {location}
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default NewsThumbnail;
