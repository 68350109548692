import { isAdmin } from "./AdminController";

function AdminComponent({children}) {
    return (
        <div>
            {isAdmin() && children}
        </div>
    );

}

export default AdminComponent;