import '../../App.css';
import '../css/MemberPage.css';
import AppFrame from '../../Common/AppFrame'
import Banner from '../../Events/EventPage/Banner';
import EventPageInfo from '../../Events/EventPage/EventPageInfo';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getMemberById } from '../../Firebase/Models/MembersModel';
import { useTranslation } from 'react-i18next';
import { getLanguage } from '../../Localization/LanguageHelper';

export const MemberTypes = [
    "Board",
    "Premier",
    "Elite",
    "Professional",
    "Corporate",
    "NotForProfit",
    "Individual",
    "LeadershipProgram",
    "Volunteer"
]

function Member() {
    const {memberId} = useParams();
    const [member, setMember] = useState({});
    const {i18n} = useTranslation();
    useEffect(() => {
        getMemberById(memberId, data => {
            setMember(data)
        })
    }, [memberId])

    const imgUrl = member.url ? member.url : '';
    const name = getLanguage(member.name, i18n);
    const description = getLanguage(member.description, i18n);

  return (
    <AppFrame>
        <Banner title={"Member Details"}/>
        <div className='MemberPage'>
            <div className='MemberImageSection'>
                <div className="MemberPageImageContainer"
                    style={{
                        backgroundImage: "url(" + process.env.PUBLIC_URL + imgUrl + ")",
                    }}
                />
            </div>
            <div className='MemberInfoContainer'>
                <h3 className='MemberName'>{name}</h3>
                <div className='MemberInfoSection '>
                    <EventPageInfo title={"Description"} content={description} hideTitle={true}/>
                </div>
            </div>
        </div>
    </AppFrame>
  );
}

export default Member;
