import './css/Events.css';
import CIcon from '@coreui/icons-react';
import { cilLocationPin } from '@coreui/icons';
import { Link } from "react-router-dom";
import Button from '../Common/Button';
import { useTranslation } from 'react-i18next';
import { cilTrash } from '@coreui/icons';
import { deleteEvent } from '../Firebase/Models/EventsModel';
import AdminComponent from '../Firebase/Admin/AdminComponent';

function EventThumbnail({imgUrl, name, description, eventColor, date, location, id}) {
    const {t} = useTranslation()
  return (
    <div className="EventThumbnailContainer"
    >
        <AdminComponent>
                    <div className='DeleteButton'
                        onClick={(e) => {
                            e.stopPropagation();
                            deleteEvent(id)
                        }}
                    >
                        <CIcon icon={cilTrash} size="custom" className=''/>
                    </div>
        </AdminComponent>
        <div className='EventThumbnailUpperSection'>
            <div className="EventImageContainer"
                style={{
                    backgroundImage: "url(" + process.env.PUBLIC_URL + imgUrl + ")",
                }}
            />
            <div 
                className='EventName' 
                style={{
                    backgroundColor: eventColor
                }}
            >{name}</div>
        </div>
        
        <div className='EventDetails'>
            {description}
        </div>
        <div className='EventRegisterContainer'>
            <div className='EventLocationContainer'> 
                <CIcon icon={cilLocationPin} size="sm" className='EventLocationSymbol'/>
                <div className='EventLocationLabel'>
                    {location}
                </div>
            </div>
            <div className='EventDate'>
                {date}
            </div>
            <Link className='EventDetailsLink' to={`/event/${id}`}>
                <Button label={t('details')} />
            </Link>
        </div>
  </div>
  );
}

export default EventThumbnail;
