import React from 'react';
import './css/NewEventPopup.css';
import NewEventPopupInput from './NewEventPopupInput';
import CIcon from '@coreui/icons-react';
import { cilCheckAlt } from '@coreui/icons';
import PopupDatePicker from '../Common/PopupDatePicker';
import PopupImageUploader from '../Common/PopupImageUploader';
import LanguageSelector from '../Common/LanguageSelector';
import { useState } from 'react';
import { addEvent } from '../Firebase/Models/EventsModel';
import { Timestamp } from '@firebase/firestore';

function NewEventPopup({close}) {
    const [language, setLanguage] = useState('en');
    const [name, setName] = useState({});
    const [eventLanguage, setEventLanguage] = useState({});
    const [location, setLocation] = useState({})
    const [date, setDate] = useState(Timestamp.now)
    const [duration, setDuration] = useState()
    const [deadline, setDeadline] = useState(Timestamp.now)
    const [speakers, setSpeakers] = useState({})
    const [description, setDescription] = useState({})
    const [url, setUrl] = useState()
    const [price, setPrice] = useState()
    const [memberPrice, setMemberPrice] = useState()
    const [priceUrl, setPriceUrl] = useState()
    const [memberPriceUrl, setMemberPriceUrl] = useState()

    const [currentNameContent, setCurrentNameContent] = useState(name[language]);
    const [currentLanguageContent, setCurrentLanguageContent] = useState(name[eventLanguage]);
    const [currentLocationContent, setCurrentLocationContent] = useState(name[location]);
    const [currentSpeakersContent, setCurrentSpeakersContent] = useState(name[speakers]);
    const [currentDescriptionContent, setCurrentDescriptionContent] = useState(description[language]);

    const updateFields = (lang) => {
        setCurrentNameContent(name[lang] ? name[lang] : '')
        setCurrentLanguageContent(eventLanguage[lang] ? eventLanguage[lang] : '')
        setCurrentLocationContent(location[lang] ? location[lang] : '')
        setCurrentSpeakersContent(speakers[lang] ? speakers[lang] : '')
        setCurrentDescriptionContent(description[lang] ? description[lang] : '')
    }

    return (
        <div 
            className="NewEventPopupContainer"
        >
            <LanguageSelector
                onSelect={(lang) => {
                    updateFields(lang);
                    setLanguage(lang)
                }}
            />
            <div className="NewEventContainer">
                <NewEventPopupInput label={'Event Name'} onChange={content => {
                    name[language] = content;
                    setName(name)
                    setCurrentNameContent(content)
                }} value={currentNameContent}/>
                <NewEventPopupInput label={'Language'} onChange={content => {
                    eventLanguage[language] = content;
                    setEventLanguage(eventLanguage)
                    setCurrentLanguageContent(content)
                }} value={currentLanguageContent}/>
                <NewEventPopupInput label={'Location'} onChange={content => {
                    location[language] = content;
                    setLocation(location)
                    setCurrentLocationContent(content)
                }} value={currentLocationContent}/>
                <PopupDatePicker label={'Date'} onChange={setDate} firebaseDate={date}/>
                <NewEventPopupInput label={'Duration'} onChange={
                    setDuration
                } value={duration}/>
                <NewEventPopupInput label={'Price'} onChange={setPrice} value={price}/>
                <NewEventPopupInput label={'PriceUrl'} onChange={setPriceUrl} value={priceUrl}/>
                <NewEventPopupInput label={'MemberPrice'} onChange={setMemberPrice} value={memberPrice}/>
                <NewEventPopupInput label={'MemberPriceUrl'} onChange={setMemberPriceUrl} value={memberPriceUrl}/>
                
                <PopupDatePicker label={'Registration Deadline'} onChange={setDeadline} 
                firebaseDate={deadline}/>
                <NewEventPopupInput label={'Speakers'} onChange={content => {
                    speakers[language] = content;
                    setSpeakers(speakers)
                    setCurrentSpeakersContent(content)
                }} value={currentSpeakersContent}/>
                <PopupImageUploader label={'Poster'} onUrlGenerated={setUrl}/>
                <NewEventPopupInput label={'Description'} value={currentDescriptionContent} onChange={content => {
                    description[language] = content
                    setDescription(description)
                    setCurrentDescriptionContent(content)
                }}/>

                <div className='NewEventPopupFooter'>
                    <div className=''> 

                    </div>

                    <div className='EventRegisterButton' onClick={() => {
                        addEvent({
                            name,
                            language: eventLanguage,
                            location,
                            date,
                            duration,
                            deadline,
                            speakers,
                            description,
                            url,
                            price,
                            priceUrl,
                            memberPrice,
                            memberPriceUrl
                        })
                        close()
                        }}>
                        <CIcon icon={cilCheckAlt} size="sm" className='EventLocationSymbol'/>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default NewEventPopup;