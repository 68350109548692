import { useTranslation } from 'react-i18next';
import './css/Menu.css';
import LanguageSwitch from './LanguageSwitch';
import MenuItem from './MenuItem';
import '../Localization/i18n'
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { mobileScreen } from '../App';
import CIcon from '@coreui/icons-react';
import { cilHamburgerMenu } from '@coreui/icons';
import { MemberTypes } from '../Members/MemberPage/Member';

function Menu() {
  const {t} = useTranslation();
  const isMobile = useMediaQuery({
    query: mobileScreen
  })
  const [showMenu, setShowMenu] = useState(!isMobile);
  const memberFilters = MemberTypes.map((memberType) => {
    return {
        label: t(memberType),
        linkTo: `/members/${memberType}`
      }
  })
  return (
    <div className="MenuContainer">
      <div className='MenuLogoContainer'>
        <div className="MenuLogoImage"
            style={{
                backgroundImage: "url(" + process.env.PUBLIC_URL + "/Images/Logo/logo_description.png)",
            }}
        />
        {isMobile && <div 
          className='MenuButton' 
          onClick={() => {
            setShowMenu(!showMenu)
          }}
        >
          <CIcon icon={cilHamburgerMenu} size="custom-size" className='MenuIcon'/>
        </div>}
      </div>
      {showMenu && <div className='MenuItems'> 
        <MenuItem title={t('home')} linkTo={"/"}/>
        <MenuItem title={t('news')} linkTo={"/news"}/>
        <MenuItem title={t('events')} items={[
            {
              label: t("all_events"),
              linkTo: "/events/all",
              subMenuItems: [
                {
                  label: t('upcoming_events'),
                  linkTo: "/events/upcoming",
                },
                {
                  label: t('past_events'),
                  linkTo: "/events/past",
                }
              ]
            }, {
              label: t('calendar'),
              linkTo: "/calendar"
            }
          ]}/>
        <MenuItem title={t('membership')} items={[
          {
            label: t('become_a_member'),
            linkTo: "/become-a-member"
          },{
            label: t('become_a_sponsor'),
            linkTo: "/become-a-sponsor"
          }, {
            label: t('all_members'),
            linkTo: "/members/all",
            subMenuItems: memberFilters
          }
          ]}/>
        <MenuItem title={t('about_us')} 
          
          items={[
            {
              label: t('about_ccec'),
              linkTo: "/about-us" 
            }, {
              label: t('ccec_charter'),
              linkTo: "/charter"
            }
          ]}
        />
      
          <div className='MenuVerticalBar'/>
          <LanguageSwitch />
      </div>}
    </div>
  );
}

export default Menu;
