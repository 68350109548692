import { useState } from "react";
import AppFrame from "../Common/AppFrame";
import CharterBulletPoints from "./CharterBulletPoints";
import Button from "../Common/Button";
import InnerPage from "./InnerPage";
import "./css/Charter.css"
import { useTranslation } from "react-i18next";

function Charter() {
  const [pageIndex, setPageIndex] = useState(0);
  const {t} = useTranslation();
  const pageTitle = t('ccec_charter_title');
  const pages = [
    <InnerPage 
      title={pageTitle}
      subtitle={"1) CCEC Council's creation"}
    >
    <div>The Canadian Chinese Entrepreneurs Council (CCEC) was organized by a number of Chinese entrepreneurs residing in Canada in December 2012; and officially 
              founded on February 9, 2017. The anniversary date for the CECC is set at February 9 each year.</div>
    </InnerPage>,
    <InnerPage 
      title={pageTitle}
      subtitle={"2) Nature and purpose of CCEC"}
    >
      <div>
            CCEC is a non-for-profit organization registered in Canada. Its main members are Chinese entrepreneurs living in Canada. The basic characteristics of CCEC’s
             membership are "non-profit and non-political". Headquarter is set in Montreal, Quebec, Canada.
            As a non-for-profit civil organization, CCEC is committed to promoting business cooperation and sustained and healthy development between China and Canada
             by promoting commercial righteousness, building an effective exchange platform between its members and between China and Canada. CCEC encourages and supports
              information exchange, resource sharing and fair and effective cooperation among members. CCEC advocates open communication, innovation, healthy competition,
               sustainable development and socially responsible entrepreneurial spirit. CCEC seeks to actively cooperate with other organizations in an understanding, 
               respectful and comprehensive manner. CCEC through the high level of business activities and active social participation,implementits own ideas and objectives 
               to expand and increase the influence and say of Chinese-Canadian enterprises and even the Chinese community in the local mainstream society.
      </div>
    </InnerPage>,
    <InnerPage 
      title={pageTitle}
      subtitle={"3) CCEC members and membership"}
    >
      <div>
            To ensure healthy and high quality development, CCEC adopts a membership system. CCEC, at the start-up stage, includes 7-9 directors and adheres
             to only absorb and develop members who meet the standards as set by the council as described below. CCEC offers positions for consultants and honorary 
             members (mainly from the Chinese and foreign political circle and business community, as well asprominent figures in the society, who recognizes the Council 
             values, and actively supports the development of CCEC).
      </div>
      <div className="CharterPageSubtitle">
            Membership qualifications and specific requirements are as follows:
      </div>
      <CharterBulletPoints 
              bulletPoints={[
                "Members must agree with the CCEC's purpose, abide by the CCEC charter and membership code of conduct, and fulfill the necessary admission procedures",
                "Members must have excellent moral cultivation, education and social reputation.",
                "Members must be owners, executives and senior professionals of Canadian-Chinese (Canadian or current residents) enterprises / companies; or Chinese enterprise/company owners, executives and senior professionals who have legal status in Canada.",
                "Members should care about the construction and development of CCEC, and actively participate in CCEC activities",
                "Members shall automatically pay membership fees and bear the obligations of CCEC",
                "Members believe in integrity, equality, friendship, tolerance and mutual respect."
              ]}
            />
            <div className="CharterPageSubtitle">
            New membership application procedure:
            </div>
            <CharterBulletPoints 
              bulletPoints={[
                "I voluntarily apply and carefully read and accept the CCEC charter",
                "Members must fill out the relevant personal information form",
                "Must have more than 2 (including 2) joint recommendation from existing members",
                "After the recommending members confirm that the applicant has interest in applying for membership in CCEC, it will be voted by the CCEC Council and the applicant who has received more than 1/2 approval is deemed to qualify for CCEC membership.",
                "The CCEC membership applicant shall become an official member of the CCEC from the date of approval and announcement by the Chairman of the board of directors."
              ]}
            />
            <div className="CharterPageSubtitle">
            Members' obligations and rights:
            </div>
            <CharterBulletPoints 
              bulletPoints={[
                "Members are obliged to comply with the CCEC charter",
                "Members have the obligation to make personal contributions to the development of CCEC: promote CCEC, recommend new members, maintain the overall social image of CCEC; endeavor to raise social awareness, cooperation and moral and economic help for the development of CCEC’s public welfare.",
                "Members have the right to know, recommend and supervise the club’s decisions, fees, activities and all related matters;the right to criticize and demand improvements from the council and the implementation department.",
                "Members have the right to withdraw: for the withdrawal of members, CCEC should promptly proceed with the withdrawal procedures, issue a notice of withdrawal and notify all members. CCEC does not refund the membership fee and annual fee paid by the member.",
                "Members are required to pay membership fee at the time of entry. Members must pay the annual fee in a timely manner, provide an explanation if missing an annual payment, and make up for the payments next year. Members who miss the payment for two consecutive years are deemed as automatic withdrawal."
              ]}
            />
    </InnerPage>,
    <InnerPage 
      title={pageTitle}
      subtitle={"4)Organization management and main responsibilities"}
    >
      <div>
          1. CCEC GeneralAssembly: held in principle once a year, with the "Entrepreneurs Forum" / awards conference over the same period, 
          initially scheduled for the third/fourth quarter of each year. The CCEC General Assembly's main responsibilities are as follows:
          </div>
          <div>
          General Assembly will focus on the consideration and adoption of the Council's proposals, to examine the work of the Council and
           audit the use of funds. At the General Assembly, the members will also elect the new Council directors when the term is up.
          </div>
          <div>
          2. “Entrepreneurs Forum" and “EntrepreneurAward”:
          The “Entrepreneurs Forum” and “Entrepreneur Award”are organized by CCEC and set in the third / fourth quarter of each year.At the time, 
          CCEC will invitethe most influential business moguls, Chinese entrepreneurs, business leaders, political figures, academics, and mainstream 
          media person. The CCEC offers special awards to encourage and promote outstanding Chinese entrepreneurs and businesses in Canada. 
          The awards include: Outstanding Chinese Entrepreneur special award, Outstanding Sino-Canadian business contribution award, SME contribution 
          award, high-tech innovation award, outstanding youth entrepreneurship award, outstanding Chinese women entrepreneurship award; etc.
          </div>
          <div>
          3. CCEC Council: CCEC has established a council with tentatively 7-9 directors. Council directors are elected by all members. 
          The Council will be re-elected once every three years, with continuous serving terms available. The council establishes 1 President, 
          3 Vice-Presidents, 1 Secretary-General (served jointly by Vice-President). The President shall be appointed by the executive committee;
           the Vice-presidents and Secretary-General shall be nominated by the President and voted by the Council board of directors. The 
           Secretary-General is responsible for the specific work of CCEC under the delegation of the President.
          </div>
          <div>
          3-1. The main responsibilities of the Council are as follows:
          <CharterBulletPoints 
              prefix={"3-1-"}
              bulletPoints={[
                "Draft CCEC’s decision on formation, direction of development and related major matters to be voted by the Council or all members at the General Assembly;",
                "Draft CCEC’s major budget, allocation and social welfare project implementation plansto be voted by the Council or all members at the General Assembly;",
                "Draft CCEC annual budget and financial statementsto be voted by all members at the General Assembly;",
                "Decideon the appointment and removal of members responsible for CCEC ’smajor projects and annual events;",
                "Decide on CCEC’s new member attraction and member removal Policy",
                "Decide on CCEC’s external partnership (target / Project) selection",
                "Determine important planning and arrangements for CCEC during the year",
                "Decide on matters related to the CCEC General Assembly",
                "Supervise, direct and standardize the implementation of specific work of its functional departments"
              ]}
            />
            <div>4.CCEC Board of Supervisors: CCEC establishes a board of supervisors, which is a supervisory body outside the CCEC governing body to ensure that CCEC does not 
            lose its founding values due to personnel changes. The board of supervisors is not involved in the implementation and management
             of CCEC's daily affairs and activities. Its main task is to supervise the revision of the contents of the future CCEC Charter and 
             has final approval authority. The board of supervisors is fixed at seven (7) members, who are elected from the founding member and 
             the current official members. The members of the board of supervisors have 1 veto right each and 4 (including 4) or more vetoes 
             shall enter into force immediately. Members of the board of supervisors may be filled with existing members if they are permanently 
             unable to perform their duties due to their withdrawal or other reasons. The member filling the vacancy shall be recommended by existing
              member of the board of supervisors then voted internally by the board of supervisors. (Note, the supervisory mechanism has not yet been 
              formed, this clause is to be further revised)

            </div>
            <div>
            5. CCEC Rules of Voting: The Council's decision-making approach is democratic centralism; by raising hand to orballot system, the absent
             directors can vote through the internet or give proxy to other members to vote on their behalf. When the Council decides that the matter 
             should be voted, the President has two votes and the other director have one vote. The relevant resolution is required to pass two thirds 
             of the votes. When the Council of directors has a major dispute, the President has the final approval power. The Council must inform all 
             members of the General Assembly. Voting may take the form of a raised hand, a ballot, an internet ballot or a delegate to a member on a proxy. 
             The number of members participating in the vote must exceed 2/3 of the total number of members for the vote to be effective and more than 2/3 
             of the number of votes in favor for the resolution to pass. When the vote is equal, the President has two votes. In the event CCEC has major 
             internal disputes, the President has the final approval power.
            </div>
        </div>
    </InnerPage>,
    <InnerPage 
      title={pageTitle}
      subtitle={"5) Financing and management of CCEC"}
    >
      <div>
          Financing: CCEC's income comes from: membership application fee, annual membership fee, corporate and individual sponsorship, charitable fundraising,
           membership donation and other channels to raise funds.
          </div>
          <CharterBulletPoints 
              bulletPoints={[
                "Member application fee: CCEC members must pay a one-time application fee, paid at the time of acceptance. The fee will vary with the development and needs of CCEC (one-time entry fee for 2017 CAD1, 000).",
                "Annual membership fee: CCEC members are required to pay annual membership fee (annual fee is tentatively set at CAD500; varying with the development and needs of CCEC). Annual membership fee is paid for the year from February 9 to February 28. CCEC may issue a receipt for the member.",
                "Corporate and individual sponsorship: CCEC accepts sponsorship of enterprises and individuals who have been approved by the CCEC Council and are enthusiastic and supportive of CCEC's public welfare undertakings. CCEC will issue receipts for enterprises and individuals providing donations.",
                "Surplus of special activities: The balance of activities organized or participated by CCEC are included in CCEC’s revenue.",
                "Member donation: In the event there’s a deficit on CCEC’s activities the difference is made up by the donation of CCEC members. In principle, all members are to provide equal donations, but can also be done by members proactively donate until the difference to make up."
              ]}
            />
    </InnerPage>,
    <InnerPage 
      title={pageTitle}
      subtitle={"6) Expense and fund management"}
    >
      <CharterBulletPoints 
              bulletPoints={[
                "CCEC will set up an account to manage member fees and external sponsorships.",
                "CCEC’s revenue and sponsorship are mainly used to fund public welfare projects established by CCEC and their related activities. No individual may use the CCEC funds for any projects without the formal approval of the Council and the President.",
                "CCEC conducts an annual public review of the accounts and sponsorship funds, and publishes annually details of the income and expenses of the current year to all members and accepts the supervision of the members.",
                "CCEC financial expenses must be approved by the President or director of the funding authority in advance of the approval, and eventually approve reimbursements.",
                "CCEC provides subsidies to its planned activities. Costsover budget incurred by the activity are, in principle, shared by the members participating in the event.",
                "Members participating in CCEC activities, in principle, enjoy discounted price; non-members participating in CCEC activities, the standard cost will be set based on specific activities."
              ]}
            />
    </InnerPage>,
    <InnerPage 
      title={pageTitle}
      subtitle={"7) CCEC activities"}
    >
    <div>
            CCEC activities include, in principle, statutory annual activities and temporary activities. CCEC's statutory annual activities are: "Canadian Chinese 
            Entrepreneurs Forum" and "Canadian Chinese outstanding Entrepreneur awards". The annual statutory activities are planned, organized and arranged by the Council. 
            The annual activities of the temporary activities must be moved by the CCEC members, more than three members moved in favor and approved by the Council before 
            implementation. CCEC annual awards ceremony is the culmination of CCEC’swork and all members are requiredto attend, absent special circumstances. Other activities,
             members may participatebased on actual situation. CCEC encourages members to actively participate in social welfare activities in the name of CCEC. Members of 
             the CCEC participating in other social / community activities in the name of CCEC should consciously abide by the relevant provisions of CCEC, pay attention to
              their own image and conduct, without compromising the overall image and interests of CCEC. No member has the right to make any commitment on behalf of CCEC without 
              the prior permission of the President. Otherwise it will be regarded as a serious violation of the CCEC principle. CCEC’sofficial representative is the President, 
              or an authorized representative by the President.
              </div>
    </InnerPage>,
    <InnerPage 
    title={pageTitle}
    subtitle={"8) Responsibility and honor"}
  > 
        <div>
        The CCEC Council assumes the trust and expectation of all members. Members of the Board shall fulfill their duties with due diligence. The official handover ceremony 
        of Council members will be held when the term is due. In order to recognize and encourage their contribution to the creation and development of CCEC, CCEC will award 
        the founding President Honorary President after the retirement; the founding directors will be awarded honorary member; President of the term honorary award and 
        Secretary-General  contribution award.
        </div>
        
  </InnerPage>,
    <InnerPage 
    title={pageTitle}
    subtitle={"9)"}
  > 
       <div>
          The charter is established by CCEC founding members and existing current members, and the governing body (the Council) must strictly abide by the regulations to 
          implement its duties.
          </div>
        
  </InnerPage>,
    <InnerPage 
    title={pageTitle}
    subtitle={"10)"}
  > 
        <div>
          Where the contents of this statute have not mentioned or updates to the contents are to be made, they must be submitted to the CCEC to be discussed by all members 
          in the next term. CCEC Council has the final interpretation of the constitution. Canadian Chinese Entrepreneurs Council (CCEC)
          </div>
        
  </InnerPage>
  ];

  return (
    <AppFrame>
      {pages.map((page, index) => {
        if (pageIndex === index) {
          return page;
        }
        return null;
      })}
      <div className="CharterButtonsContainer"> 
        {pageIndex !== 0 && <Button width={100} label={"previous"} onClick={() => {
          setPageIndex(pageIndex-1)
        }}/>}
        {pageIndex !== pages.length - 1 && <Button width={100} label={"next"} onClick={() => {
          setPageIndex(pageIndex+1)
        }}/>}
      </div>
    </AppFrame>
  );
}

export default Charter;
