import '../css/Event.css';

function EventPageInfo({title, content, hideTitle}) {
  return (
   <div className='EventPageInfoContainer'>
        {!hideTitle && <div className='EventPageInfoSubTitle'>{title}</div>}
        <div className='EventPageInfoContent'>{content}</div>
    </div>
  );
}

export default EventPageInfo;
