import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { useState } from 'react';
import './css/PopupDatePicker.css'
import { Timestamp } from '@firebase/firestore';

function PopupDatePicker({label, onChange, firebaseDate}) {
    const [date, setDate] = useState(new Date(firebaseDate.seconds * 1000));

    return (
        <div className="PopupDatePickerContainer">
            <div className='PopupDatePickerTitleContainer'>
                {label}
            </div> 
            <ReactDatePicker 
                selected={date} 
                onChange={content => {
                    setDate(content)
                    onChange(Timestamp.fromDate(content))
                }}
                showTimeSelect 
                
                dateFormat="dd/MM/yyyy HH:mm:ss"
            />
            
        </div>
    );

}

export default PopupDatePicker;