import React from 'react';
import './css/NewEventPopup.css';
import Input from '../Common/Input';
function NewEventPopupInput({label, value, onChange}) {

    return (
        <div className="NewEventPopupInputContainer">
            <div className='NewEventPopupLabel'>
                {label}
            </div> 
            <Input onChange={onChange} value={value} />
        </div>
    );

}

export default NewEventPopupInput;