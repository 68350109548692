import React from 'react';
import './css/Button.css';
function Button({label, onClick}) {

    return (
        <div 
            className="ButtonContainer"
            onClick={onClick}
        >
           {label}
        </div>
    );

}

export default Button;