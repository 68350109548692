import React from 'react';
import CIcon from '@coreui/icons-react';
import { cilCheckAlt } from '@coreui/icons';
import NewEventPopupInput from '../Events/NewEventPopupInput';
import PopupImageUploader from '../Common/PopupImageUploader';
import { useState } from 'react';
import { addSponsor } from '../Firebase/Models/SponsorsModel';
function AddSponsorPopup({close}) {
    const [year, setYear] = useState();
    const [url, setUrl] = useState();
    const [link, setLink] = useState();
    const [name, setName] = useState();

    return (
        <div 
            className="NewEventPopupContainer"
        >
            <div className="NewEventContainer">
                <NewEventPopupInput label={'Name'} height={25} onChange={setName}/>
                <PopupImageUploader
                    label={'Logo'}                   
                    onUrlGenerated={url => {
                        setUrl(url)
                    }}
                />
                <NewEventPopupInput label={'Sponsor Year'} height={25} onChange={setYear}/>
                <NewEventPopupInput label={'Link'} height={25} onChange={setLink}/>

                <div className='NewEventPopupFooter'>
                    <div className=''> 

                    </div>

                    <div className='EventRegisterButton' onClick={() => {
                        addSponsor({
                            year: year,
                            name: name,
                            url: url,
                            link: link
                        })
                        close()
                    }}>
                        <CIcon icon={cilCheckAlt} size="sm" className='EventLocationSymbol'/>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default AddSponsorPopup;