import { useState } from 'react';
import AppFrame from '../Common/AppFrame';
import MonthlyCalendar from '../Common/MonthlyCalendar';
import { getAllEvents } from '../Firebase/Models/EventsModel';
import { useEffect } from 'react';
import { printIOSDate } from '../Tools/DateTool';
import { useTranslation } from 'react-i18next';
import Banner from './EventPage/Banner';
import { getLanguage } from '../Localization/LanguageHelper';
function CalendarPage() {
  const [events, setEvents] = useState([])
  const {i18n, t} = useTranslation();
  useEffect(() => {
    getAllEvents(setEvents)
  }, [])
  const calendarData = []
  events.forEach(event => {
    calendarData.push( {
      id: event.id,
      text: getLanguage(event.description, i18n),
      start: printIOSDate(event.date),
      end:  printIOSDate(event.date)
    })
  })
  return (
    <AppFrame>
        <Banner title={t('calendar')} />
        <div className='DatePickerContainer'>
            {/* <div className='arrow left'/> */}
            <div className=''>
                {new Date().toDateString()}
            </div>
            {/* <div className='arrow right'/> */}

        </div>
        <div className='EventCalendarContainer'>
            <MonthlyCalendar events={calendarData}/>
        </div>
    </AppFrame>

  );
}

export default CalendarPage;
