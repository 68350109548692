import '../css/Subscription.css';
import '../../App.css';
import SubscriptionBullet from './SubscriptionBullet';
import Accordion from '../../Common/Accordion';
import Button from '../../Common/Button';
import { useTranslation } from 'react-i18next';
import { navigateToPayment } from '../../Payment/Stripe';
import AdminComponent from '../../Firebase/Admin/AdminComponent';
import { deleteSubscription } from '../../Firebase/Models/SubscriptionsModel';
import { cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

function Subscription({title, price, benefits, description, priceUrl, id}) {
    const {t} = useTranslation();
    const renderedBenefits = benefits.split("|").map(b => {
        return <SubscriptionBullet content={b}/>
    })
  return (
    <div className="MemberSubscriptionContainer">
        <div className='SubcriptionHeaderContainer'>
            <div className='SubscriptionTitle'>
                {title}
            </div>
            <div className='SubscriptionTitle'>
                {price}$
            <AdminComponent>
                <div className='DeleteButton'
                    onClick={(e) => {
                        e.stopPropagation();
                        deleteSubscription(id)
                    }}
                >
                    <CIcon icon={cilTrash} size="custom" className=''/>
                </div>
            </AdminComponent>
            </div>
        </div>
        <Accordion label={description}>
            {renderedBenefits}
            <Button label={t('subscribe')} onClick={() => {
                navigateToPayment([{
                    price: priceUrl, 
                    quantity: 1,
                }])
            }}/>
        </Accordion>
    </div>
  );
}

export default Subscription;
