import React from 'react';
import './css/Hyperlink.css';
function Hyperlink({imgUrl}) {

    return (
        <div 
            className="HypylinkImage"
            style={{
                backgroundImage: "url(" + process.env.PUBLIC_URL + imgUrl,
            }}
            onClick={() => {
                window.open("https://example.com/faq.html", "_blank")
            }}
        />
    );

}

export default Hyperlink;