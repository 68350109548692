import React from 'react';
import ReactQuill from 'react-quill';
import './css/PopupEditor.css'

function PopupEditor({label, disableImage, value, onChange}) {
    const tools = ['link'];
    if (!disableImage) {
        tools.push('image');
    }
    return (
        <div className="PopupEditorContainer">
            <div className='PopupEditorTitleContainer'>
                {label}
            </div> 
            <ReactQuill
                theme="snow" 
                value={value} 
                onChange={onChange}
                modules={{  
                    toolbar: {  
                    container: [  
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  
                        ['bold', 'italic', 'underline'],  
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],  
                        [{ 'align': [] }],  
                        tools,  
                        ['clean'],  
                        [{ 'color': [] }]  
                    ]
                    }
                }}  
            />
        </div>
    );

}

export default PopupEditor;