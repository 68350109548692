import React from 'react';
import CIcon from '@coreui/icons-react';
import { cilCheckAlt } from '@coreui/icons';
import NewEventPopupInput from '../Events/NewEventPopupInput';
import { useState } from 'react';
import { Timestamp } from '@firebase/firestore';
import PopupDatePicker from '../Common/PopupDatePicker';
import LanguageSelector from '../Common/LanguageSelector';
import PopupImageUploader from '../Common/PopupImageUploader';
import { addNews } from '../Firebase/Models/NewsModel';
import PopupEditor from '../Common/PopupEditor';

export default function AddNewsPopup({close}) {
    const [language, setLanguage] = useState('en');
    const [title, setTitle] = useState({});
    const [location, setLocation] = useState({})
    const [date, setDate] = useState(Timestamp.now)
    const [description, setDescription] = useState({})
    const [urls] = useState([])

    const [currentTitleContent, setCurrentTitleContent] = useState(title[language]);
    const [currentLocationContent, setCurrentLocationContent] = useState(title[location]);
    const [currentDescriptionContent, setCurrentDescriptionContent] = useState(description[language]);
    const updateFields = (lang) => {
        setCurrentTitleContent(title[lang] ? title[lang] : '')
        setCurrentLocationContent(location[lang] ? location[lang] : '')
        setCurrentDescriptionContent(description[lang] ? description[lang] : '')
    }
    return (
        <div 
            className="NewEventPopupContainer"
        >
            <LanguageSelector
                onSelect={(lang) => {
                    updateFields(lang);
                    setLanguage(lang)
                }}
            />
            <div className="NewEventContainer">
                <NewEventPopupInput label={'Title'} onChange={content => {
                    title[language] = content;
                    setTitle(title)
                    setCurrentTitleContent(content)
                }} value={currentTitleContent}/>
                <NewEventPopupInput label={'Location'} onChange={content => {
                    location[language] = content;
                    setLocation(location)
                    setCurrentLocationContent(content)
                }} value={currentLocationContent}/>
                <PopupDatePicker label={'Date'} onChange={setDate} firebaseDate={date}/>
                <PopupEditor
                    label={'Description'} disableImage={true}
                    value={currentDescriptionContent}
                    onChange={(content) => {
                        description[language] = content
                        setDescription(description)
                        setCurrentDescriptionContent(content)
                    }}
                />
                <PopupImageUploader label={'Poster'} 
                    onUrlGenerated={url => {
                        urls.push(url)
                    }}
                />

                <div className='NewEventPopupFooter'>
                    <div className=''/> 
                    <div className='EventRegisterButton' onClick={() => {
                        addNews({
                            title: title,
                            location: location,
                            date: date,
                            description: description,
                            urls: urls
                        })
                        close()
                    }}>
                        <CIcon icon={cilCheckAlt} size="sm" className='EventLocationSymbol'/>
                    </div>
                </div>
            </div>
        </div>
    );

}