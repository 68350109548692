import {getFirebaseDoc, setFirebaseDoc} from "../firebase-helper";

const collection = "descriptions";
const homeDoc = "home";
const aboutUsDoc = "about_us";
const footerDoc = "footer";

function getDescription(docName, onLoaded) {
    getFirebaseDoc(collection, docName, data => {
        onLoaded(data)
    })
}

export function getHomeDescription(onLoaded) {
    getDescription(homeDoc, onLoaded)
}

export function setHomeDescription(data) {
    setFirebaseDoc(collection, homeDoc, data)
}

export function getAboutUsDescription(onLoaded) {
    getDescription(aboutUsDoc, onLoaded)
}

export function setAboutUsDescription(data) {
    setFirebaseDoc(collection, aboutUsDoc, data)
}

export function getFooterDescription(onLoaded) {
    getDescription(footerDoc, onLoaded)
}

export function setFooterDescription(data) {
    setFirebaseDoc(collection, footerDoc, data)
}
